import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AcademiaService } from '../api-client/academia.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../ui.service';
import { SessionService } from '../api-client/session.service';
import { ExportAsService } from 'ngx-export-as';
import { config } from '../config';
import { ToastrService } from 'ngx-toastr';
import { ExamService } from '../api-client/exam.service';
export class PaperWiseStudentsComponent {
    constructor(_academiaService, _route, _router, _uiService, _exportAsService, _tosterService, _sessionService, _examService) {
        this._academiaService = _academiaService;
        this._route = _route;
        this._router = _router;
        this._uiService = _uiService;
        this._exportAsService = _exportAsService;
        this._tosterService = _tosterService;
        this._sessionService = _sessionService;
        this._examService = _examService;
        this.config = config;
        this.mode = "";
        this._academiaServiceSubscription = _academiaService.onAcademicSessionUpdate.subscribe(_ => {
            this.academiaSessions = this._academiaService.getAcademicSession();
            this.populateStudents();
        });
    }
    ngOnInit() {
        this.activeExam();
        this.academiaSessions = this._academiaService.getAcademicSession();
    }
    ngOnChanges(changes) {
        this.activeExam();
        this.populateStudents();
    }
    populateStudents() {
        if (this.paper == null)
            return;
        this.paperInDisciplineSession = this._uiService.paperInDisciplineSession;
        this._academiaService.getPaperWiseStudents(this.paper.id)
            .subscribe((result) => {
            for (var i = 0; i < result.length; i++) {
                result[i].sessionName = this.sessionDetails(result[i].academicSessionId).name;
            }
            result.sort((x, y) => {
                return x.registrationNumber.localeCompare(y.registrationNumber);
            });
            for (var k = 0; k < result.length; k++) {
                result[k].marks = 0;
            }
            this.students = result;
        });
    }
    ngOnDestroy() {
        this._academiaServiceSubscription.unsubscribe();
    }
    isEmpty() {
        return Object.keys(this.students).length == 0;
    }
    redirectTo(student) {
        this._router.navigate(["student/" + student.id]);
    }
    sessionDetails(sessionId) {
        if (this.academiaSessions == null)
            return null;
        var items = this.academiaSessions.filter((x) => x.id == sessionId);
        if (items.length > 0) {
            return items[0];
        }
        else {
            return null;
        }
    }
    studentList(paperId) {
        //this._router.navigate(["student/"+student.id]);
        this._router.navigate([{
                outlets: { rightMainPopup: ['search-students', paperId] }
            }]);
    }
    export(type, elementId, name, arg) {
        this.midTerm = false;
        this.endTerm = false;
        if (arg == 'mid') {
            this.midTerm = true;
        }
        if (arg == 'end') {
            this.endTerm = true;
        }
        var configs = { type: type, elementId: elementId };
        setTimeout(() => {
            this._exportAsService.save(configs, this.paperInDisciplineSession.discipline.name + " " + this.paper.code + "(" + this.paperInDisciplineSession.session.name + ")").subscribe(() => {
            });
        }, 10);
    }
    selectAll(element) {
        for (var i = 0; i < this.students.length; i++) {
            if (element.checked) {
                this.students[i].checked = true;
            }
            else {
                this.students[i].checked = false;
            }
        }
    }
    selectIndividual(element, studentId) {
        for (var i = 0; i < this.students.length; i++) {
            if (this.students[i].id == studentId) {
                if (element.checked) {
                    this.students[i].checked = true;
                }
                else {
                    this.students[i].checked = false;
                }
            }
        }
    }
    unlinkStudents() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            var student = this.students.filter((x) => x.checked == true);
            if (student.length == 0) {
                this._tosterService.error("Please select student(s)");
                return;
            }
            var count = 0;
            for (var i = 0; i < this.students.length; i++) {
                if (this.students[i].checked) {
                    var data = {
                        "studentId": this.students[i].id,
                        "paperCodes": [this.paper.code]
                    };
                    yield this._academiaService.unsetStudentPaper(data).toPromise().then((result) => {
                        count++;
                    });
                }
            }
            this._tosterService.success("Student(s) unlinked");
            this.populateStudents();
        });
    }
    linkStudents() {
        this._router.navigate([{
                outlets: { rightMainPopup: ['search-students', this.paper.id] }
            }]);
    }
    activeExam() {
        this._academiaService.getActiveExam().toPromise().then((result) => {
            this.activeExaminationId = result.id;
        });
    }
    submitMarks() {
        //console.log(this.activeExaminationId,this.students);return;
        if (!this.mode) {
            this._tosterService.error("Select Type");
            return;
        }
        for (var i = 0; i < this.students.length; i++) {
            var endAbsent = false;
            if (this.students[i].marks > 0) {
                endAbsent = false;
            }
            else {
                endAbsent = true;
            }
            var midAbsent = false;
            if (this.students[i].marks > 0) {
                midAbsent = false;
            }
            else {
                midAbsent = true;
            }
            if (this.mode == 'mid') {
                var studentParams = {
                    studentId: this.students[i].id,
                    paperId: this.paper.id,
                    //examinationId: this.historyData[i].children[k].examinationId,
                    examinationId: this.activeExaminationId,
                    isMidTermMarks: true,
                    marks: parseFloat(this.students[i].marks),
                    studentAbsent: midAbsent,
                    incomplete: false,
                };
            }
            else if (this.mode == 'end') {
                var studentParams = {
                    studentId: this.students[i].id,
                    paperId: this.paper.id,
                    //examinationId: this.historyData[i].children[k].examinationId,
                    examinationId: this.activeExaminationId,
                    isMidTermMarks: false,
                    marks: parseFloat(this.students[i].marks),
                    studentAbsent: endAbsent,
                    incomplete: false,
                };
            }
            //console.log(studentParams);return;
            this._examService.submitMarks(studentParams).subscribe((result) => {
                this._tosterService.success("Marks updated");
                return;
            });
        }
    }
}
