import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

export class CommonMethods {

    public static validateEmail(inputText: any) {
        var mailformat = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
        if (mailformat.test(inputText)) {
            return true;
        } else {
            return false;
        }
    }

    public static getHeaderToken() {
        var headerValues = {};
        headerValues['Content-Type'] = 'application/json';

        var token = sessionStorage.getItem("htoken");
        if (token) {
            headerValues['Authorization'] = "Bearer "+token;
        }
        var httpOptions = {
            headers: new HttpHeaders(headerValues)
        };
        
        return httpOptions;
    }

    public static capabilities(){
        return [
            {key:"ViewEmployeeRoles",code:"2000",description:""},
            {key:"ViewPapers",code:"10000",description:""},
            {key:"AddPapers",code:"10001",description:""},
            {key:"UpadtePapers",code:"10002",description:""},
            {key:"RemovePapers",code:"10003",description:""},
            {key:"ViewPapersOfOwnDiscipline",code:"10010",description:""},
            {key:"AddPapersOfOwnDiscipline",code:"10011",description:""},
            {key:"UpadatePapersOfOwnDiscipline",code:"10012",description:""},
            {key:"RemovePapersOfOwnDiscipline",code:"10013",description:""},
            {key:"ViewStudents",code:"20000",description:""},
            {key:"AddStudents",code:"20001",description:""},
            {key:"UpadateStudents",code:"20002",description:""},
            {key:"RemoveStudents",code:"20003",description:""},
            {key:"ViewStudentsOfOwnDiscipline",code:"20010",description:""},
            {key:"AddStudentsOfOwnDiscipline",code:"20011",description:""},
            {key:"UpadateStudentsOfOwnDiscipline",code:"20012",description:""},
            {key:"RemoveStudentsOfOwnDiscipline",code:"20013",description:""},
        ]
    }

    public static capabilityKeyToCode(arg:any){
        for(var i=0;i<this.capabilities.length;i++){
            if(this.capabilities[i].key==arg){
                return this.capabilities[i].code;
            }
        }
    }

    public static exportcsvHeader(key: any) {
        var title = key;
        title = title.replace(/([A-Z])/g, " $1");
        title = title.charAt(0).toUpperCase() + title.slice(1);
        return title;
      }

      public static usDateToDb(date: any) {
        if (date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [year, month, day].join('-');
        } else {
            return null;
        }

    }
}