import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, of } from 'rxjs';
import { config } from '../config';
import { map } from 'rxjs/operators';
import { DisciplineModel } from '../models/discipline.model';

@Injectable({
  providedIn: 'root'
})
export class ExamService {
  private _exams: any;

  public onExamsUpdated = new Subject();

  constructor(private _http: HttpClient) {
    
  }

  getExam(id: any) {
    return this._http.get(config.apiEndPointBase + "examination/"+id);
  }

  save(data: any): Observable<any> {
    if (data.id) {
      return this._http
        .put(config.apiEndPointBase + "examination", data, {})
        .pipe(map((response: any) => {
          return response;
        }));
    } else {
      return this._http
        .post(config.apiEndPointBase + "examination", data, {})
        .pipe(map((response: any) => {
          return response;
        }));
    }
  }

  getEmployees(disciplineId:any){
    return this._http.get(config.apiEndPointBase + "discipline/"+disciplineId+"/employees");
  }

  linkUnlinkEmployee(data:any,type:any): Observable<any> {
      return this._http
        .put(config.apiEndPointBase + "discipline/"+data.disciplineId+"/employee/"+type+"/"+data.employeeId, {}, {})
        .pipe(map((response: any) => {
          return response;
        }));
  }

  downloadSubmitMarks(examinationId: any, paperId: any, evaluatorId: any, mode: any, term: any) {
    if (term == "mid") {
      return this._http.get(config.apiEndPointBase + "examination/download-submited-mid-term-marks/" + examinationId + "/" + paperId + "/" + evaluatorId + "/" + mode);
    } else {
      return this._http.get(config.apiEndPointBase + "examination/download-submited-end-term-marks/" + examinationId + "/" + paperId + "/" + evaluatorId + "/" + mode);
    }
  }

  submitMarks(data: any): Observable<any> {
    return this._http
      .post(config.apiEndPointBase + "examination/submit-marks", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  setEvaluators(data: any): Observable<any> {
    return this._http
      .post(config.apiEndPointBase + "action/set-evaluators", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  sendSubmitMarksEmail(examinationId: any, paperId: any, mode: any, term: any) {
    if (term == "mid") {
      return this._http.get(config.apiEndPointBase + "examination/send-submited-mid-term-marks/" + examinationId + "/" + paperId + "/" + mode);
    } else {
      return this._http.get(config.apiEndPointBase + "examination/send-submited-end-term-marks/" + examinationId + "/" + paperId  + "/" + mode);
    }
  }
}
