/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header-menu.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./header-menu.component";
import * as i5 from "@angular/router";
import * as i6 from "../api-client/academia.service";
var styles_HeaderMenuComponent = [i0.styles];
var RenderType_HeaderMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderMenuComponent, data: {} });
export { RenderType_HeaderMenuComponent as RenderType_HeaderMenuComponent };
function View_HeaderMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "h4 m-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(0, i3.SlicePipe, [])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform(i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.title)), 0, 75)); var currVal_1 = ((_co.title.length > 75) ? "..." : ""); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_HeaderMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["aria-expanded", "false"], ["aria-haspopup", "true"], ["class", "btn dropdown-toggle font-weight-500"], ["data-toggle", "dropdown"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "mr-2 text-color"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ":"])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(4, null, [" ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("Language")); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.langName; _ck(_v, 4, 0, currVal_1); }); }
function View_HeaderMenuComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "em", [["class", "fa fa-circle active_row"]], null, null, null, null, null))], null, null); }
function View_HeaderMenuComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "dropdown-item font-weight-500"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectSession(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderMenuComponent_5)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.active; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_0); }); }
function View_HeaderMenuComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "section", [["class", "section-preview position-relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "button", [["aria-expanded", "false"], ["aria-haspopup", "true"], ["class", "btn dropdown-toggle font-weight-500"], ["data-toggle", "dropdown"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["class", "mr-2 text-color"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ":"])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "dropdown-menu z-depth-1"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderMenuComponent_4)), i1.ɵdid(8, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.sessions; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("Session")); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.selectedSession.name; _ck(_v, 5, 0, currVal_1); }); }
export function View_HeaderMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 28, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 27, "header", [["class", "head-main position-fixed w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 26, "div", [["class", "navbar navbar-dark lighten-2 box-shadow p-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 25, "div", [["class", "container-fluid d-flex justify-content-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "section", [["class", "d-flex align-items-center justify-content-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["class", "navbar-brand position-relative mr-3"], ["href", "javascript:void(0);"], ["title", "Menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/images/presidency_university_logo.png"], ["width", "90"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderMenuComponent_1)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 19, "div", [["class", "d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "dashboard-menu d-flex justify-content-center align-items-center font-weight-600 mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "a", [["class", "text-color"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.redirectTo("home") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-home"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Dashboard"])), (_l()(), i1.ɵeld(14, 0, null, null, 9, "section", [["class", "section-preview position-relative"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderMenuComponent_2)), i1.ɵdid(16, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 6, "div", [["class", "dropdown-menu z-depth-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 2, "a", [["class", "dropdown-item font-weight-500"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeLanguage("en_US", "English") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(19, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(21, 0, null, null, 2, "a", [["class", "dropdown-item font-weight-500"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeLanguage("bn_IN", "Bengali") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(22, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderMenuComponent_3)), i1.ɵdid(25, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(26, 0, null, null, 2, "section", [["class", "section-preview position-relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 1, "a", [["title", "logout"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 0, "em", [["aria-hidden", "true"], ["class", "fa fa-sign-out"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 8, 0, currVal_0); var currVal_1 = _co.selectedSession; _ck(_v, 16, 0, currVal_1); var currVal_4 = _co.selectedSession; _ck(_v, 25, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 19, 0, i1.ɵnov(_v, 20).transform("English")); _ck(_v, 19, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 22, 0, i1.ɵnov(_v, 23).transform("Bengali")); _ck(_v, 22, 0, currVal_3); }); }
export function View_HeaderMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header-menu", [], null, null, null, View_HeaderMenuComponent_0, RenderType_HeaderMenuComponent)), i1.ɵdid(1, 245760, null, 0, i4.HeaderMenuComponent, [i5.Router, i6.AcademiaService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderMenuComponentNgFactory = i1.ɵccf("app-header-menu", i4.HeaderMenuComponent, View_HeaderMenuComponent_Host_0, { title: "title" }, {}, []);
export { HeaderMenuComponentNgFactory as HeaderMenuComponentNgFactory };
