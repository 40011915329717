import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { config } from '../../app/config';
import { SessionModel } from '../models/session.model';
import { StudentModel } from '../models/student.model';
import { DisciplineModel } from '../models/discipline.model';

@Injectable({
  providedIn: 'root'
})
export class AcademiaService {
  private _programs: any;
  private _disciplines: any;
  private _academicSession: any;
  private _selectedAcademicSession: any;

  public onProgramsUpdated = new Subject();
  public onAcademicSessionUpdate = new Subject();
  public onDisciplinesUpdated = new Subject();

  constructor(private _http: HttpClient) {
    this.loadInitialData();
  }

  public loadInitialData(){
    const _http = this._http;
    // Don't call these APIs if user not logged in
    const userToken = sessionStorage.getItem("htoken");
    if (userToken && userToken != "") {
      _http.get(config.apiEndPointBase + "academia/sessions").subscribe((result: any) => {
        result.sort((x: any, y: any) => {
          return x.name.localeCompare(y.name);
        });
        this._academicSession = result;
        let storedSelectedSession = localStorage.getItem("selectSession");
        if (storedSelectedSession) {
          this._selectedAcademicSession = JSON.parse(storedSelectedSession);
        } else {
          var selectedSessions = result.filter((x: any) => x.active);
          if (selectedSessions.length > 0) {
            this._selectedAcademicSession = selectedSessions[0];
          }
        }
        this.onAcademicSessionUpdate.next();
      });

      _http.get(config.apiEndPointBase + "academia/programs").subscribe((result: any) => {
        result.sort((x: any, y: any) => {
          return x.name.localeCompare(y.name);
        });
        this._programs = result;
        this.onProgramsUpdated.next();
      });

      _http.get(config.apiEndPointBase + "academia/disciplines").subscribe((result: any) => {
        result.sort((x: any, y: any) => {
          return x.name.localeCompare(y.name);
        });
        this._disciplines = result;
        this.onDisciplinesUpdated.next();
      });
    }
  }

  public getPrograms() {
    return this._programs;
  }

  public getProgram(id: any) {
    if (this._programs == null) return null;
    var items = this._programs.filter((x: any) => x.id == id);
    if (items.length > 0) {
      return items[0];
    } else {
      return null;
    }
  }

  public getDisciplines() {
    return this._disciplines;
  }

  public getDiscipline(id: any) {
    if (this._disciplines == null) return null;
    var items = this._disciplines.filter((x: any) => x.id == id);
    if (items.length > 0) {
      return items[0];
    } else {
      return null;
    }
  }

  public getAcademicSession() {
    return this._academicSession;
  }

  public getActiveAcademicSession() {
    return this._selectedAcademicSession;
  }

  public setSelectedSession(session: any) {
    localStorage.setItem("selectSession", JSON.stringify(session));
    this._selectedAcademicSession = session;
    this.onAcademicSessionUpdate.next();
  }

  getSessionDetails(sessionId: any) {
    if (this._academicSession == null) return null;
    var items = this._academicSession.filter((x: any) => x.id == sessionId);
    if (items.length > 0) {
      return items[0];
    } else {
      return null;
    }
  }

  getSessionWiseExams(sessionId: string) {
    return this._http.get(config.apiEndPointBase + "academia/examinations-in-session/" + sessionId);
  }

  saveSession(data: any) {
    return this._http
      .post(config.apiEndPointBase + "academia/session", data, {})
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return new SessionModel().deserialize(response);
      }));
  }

  papers(data: any) {
    return this._http
      .post(config.apiEndPointBase + "academia/papers-with-active-examiners", data, {})
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  students(data: any) {
    return this._http
      .post(config.apiEndPointBase + "academia/students", data, {})
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  saveStudent(data: any) {
    return this._http
      .post(config.apiEndPointBase + "academia/session", data, {})
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return new StudentModel().deserialize(response);
      }));
  }

  saveDiscipline(data: any) {
    return this._http
      .post(config.apiEndPointBase + "academia/discipline", data, {})
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return new DisciplineModel().deserialize(response);
      }));
  }

  getPaperWiseStudents(paperId: string) {
    return this._http.get(config.apiEndPointBase + "academia/students-in-paper/" + paperId);
  }

  getActiveExam() {
    return this._http.get(config.apiEndPointBase + "academia/examination/active/");
  }

  unsetStudentPaper(data: any) {
    return this._http
      .post(config.apiEndPointBase + "academia/unset-student-papers", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  setStudentPaper(data: any) {
    return this._http
      .post(config.apiEndPointBase + "academia/set-student-papers", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  getAssignedPapers() {
    return this._http.get(config.apiEndPointBase + "academia/papers-assigned");
  }

  getAssignedPaperDetails(paperId: string) {
    return this._http.get(config.apiEndPointBase + "academia/papers-assigned/" + paperId);
  }

}
