import { environment } from '../environments/environment';
const ɵ0 = {
    parseInput: 'LL LT',
    fullPickerInput: 'YYYY-MM-DD',
    datePickerInput: 'LL',
    timePickerInput: 'LT',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MM YYYY',
};
export class AppModule {
    constructor() {
        if (ENV_OVERWRITE) {
            for (const key in ENV_OVERWRITE) {
                environment[key] = ENV_OVERWRITE[key];
            }
        }
    }
}
export { ɵ0 };
