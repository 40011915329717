import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExportAsService } from 'ngx-export-as';
import { ToastrService } from 'ngx-toastr';
import { AcademiaService } from '../api-client/academia.service';
import { ExamService } from '../api-client/exam.service';
import { paperService } from '../api-client/paper.service';
import { config } from '../config';
import { UiService } from '../ui.service';
export class StudentMarksSubmitComponent {
    constructor(_paperService, _academiaService, _uiService, _route, _tosterService, _examService, _exportAsService, _router) {
        this._paperService = _paperService;
        this._academiaService = _academiaService;
        this._uiService = _uiService;
        this._route = _route;
        this._tosterService = _tosterService;
        this._examService = _examService;
        this._exportAsService = _exportAsService;
        this._router = _router;
        this.mode = "";
        this.env = config;
        this.submitMidTerm = false;
        this.submitEndTerm = false;
        this.isApply = false;
        this.invalidMarks = [];
        this.isPreview = true;
        this.invalidAbsents = [];
        this.isSubmit = false;
        this.isMidTermExpair = false;
        this.isEndTermExpair = false;
        this.alreadyMarksSubmit = false;
        this._academiaServiceSubscription = _academiaService.onAcademicSessionUpdate.subscribe(_ => {
            this.academiaSessions = this._academiaService.getAcademicSession();
            this.populateStudents();
        });
    }
    ngOnInit() {
        this.today = new Date();
        this._route.params.subscribe(params => {
            this.paperId = params.id;
            if (this.paperId) {
                this.paperDetails();
                this.populateStudents();
                this.activeExam();
                this.getPaperDetails(this.paperId);
            }
        });
    }
    paperDetails() {
        if (this.paperId == null)
            return;
        this._paperService.getPaper(this.paperId)
            .subscribe((result) => {
            this.paper = result;
            this._uiService.changeAppTitle.next("Paper: " + result.name);
            this.session = this._academiaService.getSessionDetails(result.academicSessionId);
            this.paperSession = { "paperId": this.paperId, "session": this.session };
            this.discipline = this._academiaService.getDiscipline(result.disciplineId);
            this._uiService.setpaperInDisciplineSession({ discipline: this.discipline, session: this.session });
            this._uiService.setpaper(this.paper);
        });
    }
    getPaperDetails(paperId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.paperId == null)
                return;
            this._academiaService.getAssignedPaperDetails(paperId).subscribe((result) => {
                this.paperData = result;
                this.endTermMarksSubmitedOn = this.paperData.endTermMarksSubmitedOn;
                this.midTermMarksSubmitedOn = this.paperData.midTermMarksSubmitedOn;
                var midItems = this.paperData.activeRequests.filter((x) => x.type == 4);
                var endItems = this.paperData.activeRequests.filter((x) => x.type == 8);
                console.log('this.paperData', this.paperData);
                if (this.paperData.allowSubmitMidTermMarks) {
                    this.submitMidTerm = true;
                }
                else {
                    this.submitMidTerm = false;
                }
                if (this.paperData.allowSubmitEndTermMarks) {
                    this.submitEndTerm = true;
                }
                else {
                    this.submitEndTerm = false;
                }
                if (midItems.length > 0 && this.paperData.allowSubmitMidTermMarks) {
                    this.mode = 'mid';
                    this.fullMarks = this.paperData.paper.midTermAllocatedMarks;
                    this.midTerms = midItems[0];
                    this.midTermRemainingDays = this.remainingDays(this.midTerms.expiryOn);
                    if (this.midTermMarksSubmitedOn) {
                        this.alreadyMarksSubmit = false;
                    }
                    else {
                        this.alreadyMarksSubmit = true;
                    }
                    if (this.today > new Date(this.midTerms.expiryOn)) {
                        this.isMidTermExpair = true;
                    }
                }
                if (endItems.length > 0) {
                    this.mode = 'end';
                    this.fullMarks = this.paperData.paper.endTermAllocatedMarks;
                    this.endTerms = endItems[0];
                    this.endTermRemainingDays = this.remainingDays(this.endTerms.expiryOn);
                    if (this.endTermMarksSubmitedOn) {
                        this.alreadyMarksSubmit = false;
                    }
                    else {
                        this.alreadyMarksSubmit = true;
                    }
                    if (this.today > new Date(this.endTerms.expiryOn)) {
                        this.isEndTermExpair = true;
                    }
                }
                if (this.submitMidTerm && this.submitEndTerm) {
                    this.mode = '';
                    this.fullMarks = null;
                }
            }, error => {
                this._tosterService.error(error.message);
            });
        });
    }
    populateStudents() {
        if (this.paperId == null)
            return;
        this.paperInDisciplineSession = this._uiService.paperInDisciplineSession;
        this._academiaService.getPaperWiseStudents(this.paperId)
            .subscribe((result) => {
            for (var i = 0; i < result.length; i++) {
                var session = this.sessionDetails(result[i].academicSessionId);
                var name = '';
                if (session) {
                    name = session.name;
                }
                result[i].sessionName = name;
            }
            result.sort((x, y) => {
                return x.registrationNumber.localeCompare(y.registrationNumber);
            });
            for (var k = 0; k < result.length; k++) {
                result[k].marks = 0;
                result[k].absent = 2;
            }
            this.students = result;
        });
    }
    sessionDetails(sessionId) {
        if (this.academiaSessions == null)
            return null;
        var items = this.academiaSessions.filter((x) => x.id == sessionId);
        if (items.length > 0) {
            return items[0];
        }
        else {
            return null;
        }
    }
    activeExam() {
        this._academiaService.getActiveExam().toPromise().then((result) => {
            this.activeExaminationId = result.id;
        });
    }
    submitMarks() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.mode) {
                this._tosterService.error("Select Type");
                return;
            }
            try {
                for (var i = 0; i < this.students.length; i++) {
                    var isAbsent = false;
                    if (this.students[i].absent == 0) {
                        isAbsent = true;
                    }
                    else {
                        if (this.students[i].marks == 0 && this.students[i].absent == 2) {
                            this._tosterService.error("Please select Absent or Zero Obtained");
                            return;
                        }
                        else {
                            isAbsent = false;
                        }
                    }
                    if (!this.students[i].marks || this.students[i].marks == '') {
                        this.students[i].marks = 0;
                    }
                    if (this.mode == 'mid') {
                        var studentParams = {
                            studentId: this.students[i].id,
                            paperId: this.paper.id,
                            examinationId: this.activeExaminationId,
                            isMidTermMarks: true,
                            marks: parseFloat(this.students[i].marks),
                            studentAbsent: isAbsent,
                            incomplete: false,
                            mode: this.paperData.type
                        };
                    }
                    else if (this.mode == 'end') {
                        var studentParams = {
                            studentId: this.students[i].id,
                            paperId: this.paper.id,
                            examinationId: this.activeExaminationId,
                            isMidTermMarks: false,
                            marks: parseFloat(this.students[i].marks),
                            studentAbsent: isAbsent,
                            incomplete: false,
                            mode: this.paperData.type
                        };
                    }
                    yield this._examService.submitMarks(studentParams).toPromise();
                }
                yield this._examService.sendSubmitMarksEmail(this.activeExaminationId, this.paper.id, 2, this.mode).toPromise();
                this._tosterService.success("Marks updated");
                this.isApply = true;
                this.isSubmit = true;
                this.paperData.activeRequests.splice(0, 1);
                yield this.getPaperDetails(this.paperId);
                $('#OkModal').modal('toggle');
                // if (this.paperData.activeRequests.length == 0) {
                //   $('#OkModal').modal('toggle');
                // } else {
                //   await this.getPaperDetails(this.paperId);
                // }
            }
            catch (e) {
                this._tosterService.error(e);
            }
        });
    }
    clickOk() {
        $('#OkModal').modal('toggle');
        this._router.navigate(["/evaluter-papers"]);
    }
    changeType() {
        if (this.mode == 'end') {
            this.fullMarks = this.paperData.paper.endTermAllocatedMarks;
            if (this.endTermMarksSubmitedOn) {
                this.alreadyMarksSubmit = false;
            }
            else {
                this.isApply = false;
                this.isPreview = true;
                this.isSubmit = false;
                this.alreadyMarksSubmit = true;
            }
        }
        else if (this.mode == 'mid') {
            this.fullMarks = this.paperData.paper.midTermAllocatedMarks;
            if (this.midTermMarksSubmitedOn) {
                this.alreadyMarksSubmit = false;
            }
            else {
                this.isApply = false;
                this.isPreview = true;
                this.isSubmit = false;
                this.alreadyMarksSubmit = true;
            }
        }
        for (var k = 0; k < this.students.length; k++) {
            this.students[k].marks = 0;
            this.students[k].absent = 2;
        }
        this.invalidMarks = [];
        this.invalidAbsents = [];
    }
    enterMarks(e, index) {
        if (this.fullMarks != 'Select Type') {
            const num = Number(e.target.value) % 1 === 0;
            if (isNaN(e.target.value)) {
                this.isApply = true;
                if (!this.invalidMarks[index] || this.invalidMarks[index] == false) {
                    this.invalidMarks[index] = !this.invalidMarks[index];
                }
                this._tosterService.error("Enter valid marks");
            }
            else if (num === false) {
                const myArray = e.target.value.split(".");
                if (myArray.length > 1 && myArray[1] != 5) {
                    this.isApply = true;
                    if (!this.invalidMarks[index] || this.invalidMarks[index] == false) {
                        this.invalidMarks[index] = !this.invalidMarks[index];
                    }
                    this._tosterService.error("Enter valid . number");
                }
            }
            else if (e.target.value > this.fullMarks) {
                this.students[index].absent = 2;
                this.isApply = true;
                if (!this.invalidMarks[index] || this.invalidMarks[index] == false) {
                    this.invalidMarks[index] = !this.invalidMarks[index];
                }
                this.invalidAbsents[this.students[index].id] = false;
                this._tosterService.error("The entered marks " + e.target.value + " is greater than " + this.fullMarks);
            }
            else {
                this.invalidAbsents[this.students[index].id] = false;
                this.students[index].absent = 2;
                this.invalidMarks[index] = false;
                this.isApply = false;
                const isInvalid = this.invalidMarks.includes(true);
                if (isInvalid) {
                    this.isApply = true;
                }
            }
        }
    }
    changeIsPreview(preview) {
        this.isPreview = !preview;
    }
    showPreview(preview) {
        if (!this.mode) {
            this._tosterService.error("Select Type");
            return;
        }
        var tempAbsent = [];
        var tempInvalidMarks = [];
        for (var i = 0; i < this.students.length; i++) {
            if (!this.students[i].marks || this.students[i].marks == '') {
                this.students[i].marks = 0;
            }
            if (this.students[i].marks == 0 && this.students[i].absent == 2) {
                if (!this.invalidAbsents[this.students[i].id] || this.invalidAbsents[this.students[i].id] == false) {
                    this.invalidAbsents[this.students[i].id] = !this.invalidAbsents[this.students[i].id];
                }
                tempAbsent.push(i);
            }
            if (this.students[i].marks > this.fullMarks) {
                if (!this.invalidMarks[i] || this.invalidMarks[i] == false) {
                    this.invalidMarks[i] = !this.invalidMarks[i];
                }
                const isInvalid = this.invalidMarks.includes(true);
                if (isInvalid) {
                    this.isApply = true;
                }
                tempAbsent.push(i);
                this._tosterService.error("The entered marks " + this.students[i].marks + " is greater than " + this.fullMarks);
            }
        }
        if (tempAbsent.length == 0) {
            this.isPreview = !preview;
            this.invalidAbsents = [];
        }
        else {
            this._tosterService.error("Please select Absent or Zero Obtained or Valid marks");
        }
    }
    onItemChange(student) {
        this.invalidAbsents[student.id] = false;
    }
    remainingDays(date) {
        let todaydate = new Date().getTime();
        let expireDate = new Date(date).getTime();
        if (expireDate < todaydate)
            return "";
        let diffTime = Math.abs(new Date().valueOf() - new Date(date).valueOf());
        let days = diffTime / (24 * 60 * 60 * 1000);
        return Math.round(days);
    }
}
