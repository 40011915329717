import { Component, OnInit } from '@angular/core';
import { AcademiaService } from '../api-client/academia.service';
import { Subscription } from 'rxjs';
import { config } from '../config';
import { UiService } from '../ui.service';
import { ExamService } from '../api-client/exam.service';
import { count } from 'rxjs/operators';
import { DisciplineService } from '../api-client/discipline.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-hod-wise-discipline',
  templateUrl: './hod-wise-discipline.component.html',
  styleUrls: ['./hod-wise-discipline.component.css']
})
export class HodWiseDisciplineComponent implements OnInit {

  disciplines: any;
  academiaSessions: any;
  papers: any;
  env = config;
  disciplineDetails: any;
  totalCount: number = 0;
  disciplineSearchSettings = {
    singleSelection: false,
    text: "Select Discipline",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    badgeShowLimit: 3
  };
  filterKeyword = "";
  filterDiscipline = [];
  filterSemester = 0;
  filterLevel:any = 0;
  filterSession = "";
  filters = {};
  activeSession: any;
  activeExam: any;
  showSemesters = [];

  private _academiaServiceSubscription: Subscription;
  constructor(
    private _academiaService: AcademiaService,
    private _disciplineService: DisciplineService,
    private _router: Router,
    private _tosterService: ToastrService,
    private _uiService: UiService,
  ) {
    this._academiaServiceSubscription = _academiaService.onDisciplinesUpdated.subscribe(_ => {
      this.academiaSessions = this._academiaService.getAcademicSession();
      this.activeSession = this._academiaService.getActiveAcademicSession();
      this.filterSession = this.activeSession.id;
    });
  }

  ngOnInit() {
    const userToken = sessionStorage.getItem("htoken");
    if(userToken && userToken!=''){
      this._academiaService.loadInitialData();
    }
    this._disciplineService.getMyDisciplines().subscribe((result:any)=>{
      this.disciplines = result;
    })
    this._uiService.changeAppTitle.next("Disciplines");
    this._academiaService.getActiveExam().subscribe((result: any) => {
      this.showSemesters = [];
      for (var i = 0; i < result.programs.length; i++) {
        for (var s = 0; s < result.programs[i].semesters.length; s++) {
          if (!this.showSemesters.includes(result.programs[i].semesters[s].semester)) {
            this.showSemesters.push(result.programs[i].semesters[s].semester);
          }
        }
      }
    });
    this.academiaSessions = this._academiaService.getAcademicSession();
    this.activeSession = this._academiaService.getActiveAcademicSession();

    if(this.activeSession){
      this.filterSession = this.activeSession.id;
    }
  }

  paperFilter() {
    if(this.filterDiscipline.length==0){
      this._tosterService.error("Please select discipline.");
      return false;
    }
    var level = [];
    if(this.filterLevel){
      level.push(Number(this.filterLevel));
    }
    var data = {
      "freeText": this.filterKeyword,
      "academicSessionId": this.filterSession,
      "disciplineIds": this.filterDiscipline.map(x => x.id),
      "semester": this.filterSemester,
      "levels": level,
      "startIndex": 0,
      "pageSize": 0
    };
    this._academiaService.papers(data).subscribe((result: any) => {
      this.papers = {};
      this.totalCount = result.totalCount;
      var result = result.items;

      for (var i = 0; i < result.length; i++) {
        if (!this.papers[result[i].semester] && this.showSemesters.includes(result[i].semester)) {
          this.papers[result[i].semester] = [];
        }
       if (this.papers[result[i].semester]) {
          this.papers[result[i].semester].push(result[i]);
        }

      }
      //console.log(this.papers);
      /* for (var i = 0; i < result.items.length; i++) {
        result.items[i].sessionName = this._academiaService.getSessionDetails(result.items[i].academicSessionId).name;
      } */
      //this.papers = result.items;

    })
  }

  paperConfigure(paper: any) {
    window.location.href="paper/"+paper.id;
    //this._router.navigate(["paper/"+paper.id]);
    return false;
  }

}
