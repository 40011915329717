import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { config } from '../config';
import { map } from 'rxjs/operators';
import { DisciplineModel } from '../models/discipline.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class DisciplineService {
    constructor(_http) {
        this._http = _http;
        this.onDisciplinesUpdated = new Subject();
    }
    getDiscipline(id) {
        return this._http.get(config.apiEndPointBase + "discipline/" + id);
    }
    saveDiscipline(data) {
        if (data.id) {
            return this._http
                .put(config.apiEndPointBase + "discipline", data, {})
                .pipe(map((response) => {
                return new DisciplineModel().deserialize(response);
            }));
        }
        else {
            return this._http
                .post(config.apiEndPointBase + "discipline", data, {})
                .pipe(map((response) => {
                return new DisciplineModel().deserialize(response);
            }));
        }
    }
    getEmployees(disciplineId) {
        return this._http.get(config.apiEndPointBase + "discipline/" + disciplineId + "/employees");
    }
    linkUnlinkEmployee(data, type) {
        return this._http
            .put(config.apiEndPointBase + "discipline/" + data.disciplineId + "/employee/" + type + "/" + data.employeeId, {}, {})
            .pipe(map((response) => {
            return response;
        }));
    }
    getMyDisciplines() {
        return this._http.get(config.apiEndPointBase + "discipline/my-disciplines");
    }
}
DisciplineService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DisciplineService_Factory() { return new DisciplineService(i0.ɵɵinject(i1.HttpClient)); }, token: DisciplineService, providedIn: "root" });
