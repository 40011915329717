import { Deseriazable } from './deserializable.model';

export class StudentModel implements Deseriazable{
    
    id: string;
    academicProgramId: string;
    name: string;
    registrationNumber: string;
    academicSessionId:string;
    address: string;
    batchStartYear: string;
    bloodGroup: string;
    caste: string;
    classRollNumber: string;
    currentSemester: string;
    discontinued: string;
    email: string;
    emailSignature: string;
    examinationRollNumber: string;
    gender: string;
    mobile: string;
    parentName: string;
    percentile: string;
    registrationYear: string;
    dateOfBirth:string;
    nadId:string;
    aadharNumber:string;
    unqualified:string;


    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }

}