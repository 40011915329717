import { Component, OnInit, OnDestroy, Input, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationService } from '../api-client/organization.service';
import { DepartmentService } from '../api-client/department.service';
import { Subscription } from 'rxjs';
import { isNumber } from 'util';
import { config } from '../config';
import { AcademiaService } from '../api-client/academia.service';
import { paperService } from '../api-client/paper.service';
import { UiService } from '../ui.service';
import { DisciplineService } from '../api-client/discipline.service';
import { SessionService } from '../api-client/session.service';
import { UtilityService } from '../api-client/utility.service';
import { ToastrService } from 'ngx-toastr';
import { ExportAsService } from 'ngx-export-as';
import { ExamService } from '../api-client/exam.service';

@Component({
  selector: 'app-examiners',
  templateUrl: './examiners.component.html',
  styleUrls: ['./examiners.component.css']
})
export class ExaminersComponent implements OnInit, OnChanges, OnDestroy {

  @Input() paper: any;
  paperId: any;
  employees: any;
  setExaminer = [];
  count: number;
  exams = [];
  examDetails: any;
  examiners = [];
  reviewers = [];
  examinerData = [];
  reviewerData = [];
  numberOfExaminer: number;
  numberOfReviewer: number;
  paperDetails: any;
  sessionName: any;
  selectedExam: any;
  activeExam: any;
  examRadioVal: any;
  revRadioVal: any;
  showPaperConfiguration: boolean = false;
  selectedSession: any;
  populatingExams: any;
  marksEntryHeader = config.marksEntryHeader;
  examiner: any;
  students: any;
  private _paramDepartmentId: any;

  private _organizationServiceSubscription: Subscription;
  private _paramsChangeSubscriber: Subscription;
  private _academiaServiceSubscription: Subscription;
  selectedExaminerArray: any;
  selectedReviewerArray: any;
  session: any;
  selectedEvaluators: any = [];
  thirdExaminer = [];
  selectedThirdExaminerArray: any;
  constructor(
    private _route: ActivatedRoute,
    private _organizationService: OrganizationService,
    private _academiaService: AcademiaService,
    private _paperService: paperService,
    private _sessionService: SessionService,
    private _uiService: UiService,
    private _disciplineService: DisciplineService,
    private _utilityService: UtilityService,
    private _departmentService: DepartmentService,
    private _tosterService: ToastrService,
    private _examService: ExamService,
    private _exportAsService: ExportAsService,
    private _router: Router,
  ) {
    this._academiaService.getActiveExam().subscribe((result: any) => {
      this.activeExam = result;
    });
    this._academiaServiceSubscription = this._academiaService.onAcademicSessionUpdate.subscribe(_ => {
      let activeSession = this._academiaService.getActiveAcademicSession();
      if (this.selectedSession == null || this.selectedSession.id != activeSession.id) {
        this.selectedSession = this._academiaService.getActiveAcademicSession();
        this.getExams(this.selectedSession);
      }
    });
    this._uiService.papereSet.subscribe((paper: any) => {
      this.paperDetails = paper;
      this.paperId = paper.id;
      this.getEmployees(paper.disciplineId);
      var subscribe = this._sessionService.getSession(paper.academicSessionId).subscribe((result: any) => {
        subscribe.unsubscribe();
        if (this.sessionName) return;
        this.sessionName = result.name;
        if (this.paper) {
          //this.getExams(this.paper.academicSessionId);
        }
      })
    });

    this._utilityService.getEmpList.subscribe((data) => {
      this.selectedExaminerArray = data;
    });
    this._utilityService.getReviewerList.subscribe((data) => {
      this.selectedReviewerArray = data;
    });
    this._utilityService.getThirdExaminerList.subscribe((data) => {
      this.selectedThirdExaminerArray = data;
    });
  }

  ngOnInit() {
    if (this.paperDetails && this.paperDetails.academicSessionId) {
      this.session = this._academiaService.getSessionDetails(this.paperDetails.academicSessionId);
    }

  }

  getEmployees(disciplineId: any) {
    this._disciplineService.getEmployees(disciplineId)
      .subscribe((employees: any) => {
        this.employees = employees;
      });
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
  }

  getExams(session: any) {
    if (this.populatingExams) return;
    this.populatingExams = true;
    this._academiaService.getSessionWiseExams(session.id).subscribe((result: any) => {
      this.exams = result;
      this.examiners = [];
      this.reviewers = [];
      this.thirdExaminer = [];
      if (this.activeExam.academicSessionId == this.selectedSession.id) {
        this.selectExam(this.activeExam.id);
      }
      this.populatingExams = false;
    })
  }

  selectExam(examId: any) {
    this.examiners = [];
    this.reviewers = [];
    this.thirdExaminer = [];
    if (!examId) return null;
    this._paperService.getEvaluators(this.paper.id, examId).subscribe((result: any) => {
      this.selectedEvaluators = result;
      for (var i = 0; i < result.length; i++) {
        if (result[i].type == 2) {
          this.examiners.push(result[i]);
        } else if (result[i].type == 4) {
          this.reviewers.push(result[i]);
        } else {
          this.thirdExaminer.push(result[i]);
        }
      }
    })
  }

  setExaminers() {
    if (isNaN(this.count)) {
      this.count = null;
    }
    this.setExaminer = [];
    for (var i = 0; i < this.count; i++) {
      this.setExaminer.push({ "id": i, "name": "amit" + i });
    }
  }

  ngOnDestroy(): void {
    //this._organizationServiceSubscription.unsubscribe();
  }

  addExamRow(arg: any) {
    this.examinerData = [];
    this.numberOfExaminer = arg;
    for (var i = 1; i <= arg; i++) {
      this.examinerData.push({ examinerId: null, examinationId: this.selectedExam, alocatedEndTermMarks: null, type: 2, allowSubmitMidTermMarks: false, allowSubmitEndTermMarks: false, paperCode: this.paperDetails.code, paperId: this.paperDetails.id, paperName: this.paperDetails.name, paperSessionId: this.paperDetails.academicSessionId, paperSessionName: this.sessionName, midTermMarksSubmitedOn: null, endTermMarksSubmitedOn: null });
    }

  }

  addRevRow(arg: any) {
    this.reviewerData = [];
    this.numberOfReviewer = arg;
    for (var i = 1; i <= arg; i++) {
      this.reviewerData.push({ examinerId: null, examinationId: this.selectedExam, alocatedEndTermMarks: null, type: 1, allowSubmitMidTermMarks: false, allowSubmitEndTermMarks: false, paperCode: this.paperDetails.code, paperId: this.paperDetails.id, paperName: this.paperDetails.name, paperSessionId: this.paperDetails.academicSessionId, paperSessionName: this.sessionName, midTermMarksSubmitedOn: null, endTermMarksSubmitedOn: null });
    }

  }

  updateExaminer() {
    this.examinerData = [];
    for (var i = 1; i <= this.numberOfExaminer; i++) {
      this.examinerData.push({ examinerId: null, examinationId: this.selectedExam, alocatedEndTermMarks: null, type: 2, allowSubmitMidTermMarks: false, allowSubmitEndTermMarks: false, paperCode: this.paperDetails.code, paperId: this.paperDetails.id, paperName: this.paperDetails.name, paperSessionId: this.paperDetails.academicSessionId, paperSessionName: this.sessionName, midTermMarksSubmitedOn: null, endTermMarksSubmitedOn: null });
    }
  }

  updateReviewer() {
    this.reviewerData = [];
    for (var i = 1; i <= this.numberOfReviewer; i++) {
      this.reviewerData.push({ examinerId: null, examinationId: this.selectedExam, alocatedEndTermMarks: null, type: 1, allowSubmitMidTermMarks: false, allowSubmitEndTermMarks: false, paperCode: this.paperDetails.code, paperId: this.paperDetails.id, paperName: this.paperDetails.name, paperSessionId: this.paperDetails.academicSessionId, paperSessionName: this.sessionName, midTermMarksSubmitedOn: null, endTermMarksSubmitedOn: null });
    }
  }

  save() {

    if (this.examinerData.length == 0) {
      this._tosterService.error("Examiner Data can not be blank");
      return;
    }
    if (this.reviewerData.length == 0) {
      this._tosterService.error("Reviewer Data can not be blank");
      return;
    }

    for (var i = 0; i < this.examinerData.length; i++) {
      if (this.examinerData[i].examinerId == null || this.examinerData[i].examinerId == "") {
        this._tosterService.error("Examiner can not be blank");
        return;
      }
      this.examinerData[i].allowSubmitMidTermMarks = false;
    }
    if (this.examRadioVal) {
      this.examinerData[this.examRadioVal].allowSubmitMidTermMarks = true;
    }

    for (var i = 0; i < this.reviewerData.length; i++) {
      if (this.reviewerData[i].examinerId == null || this.reviewerData[i].examinerId == "") {
        this._tosterService.error("Reviewer can not be blank");
        return;
      }
      this.reviewerData[i].allowSubmitMidTermMarks = false;
    }
    //this.reviewerData[this.revRadioVal].allowSubmitMidTermMarks = true;

    var employeedata = this.examinerData.concat(this.reviewerData);
    this._utilityService.setEvaluators(employeedata).subscribe((result: any) => {
      this._tosterService.success("Data saved");
      this.selectExam(this.selectedExam);
      return false;
    })
  }

  radioVal(arg: any, val: any) {
    if (arg == 'exam') {
      this.examRadioVal = null;
      this.examRadioVal = val;
    }
    if (arg == 'rev') {
      this.revRadioVal = null;
      this.revRadioVal = val;
    }
  }

  previewCsvData(fieldMaps: any, results: any) {
    this._uiService.indeterminateProgressTextSet.next('Arrange data...');
    var columns = [];
    var columnsWidth = {};
    var columnSources = [];

    columnSources['paperType'] = Object.keys(config.paperType).map((key) => {
      return { id: Number(key), name: config.paperType[key] };
    });

    columnSources['level'] = Object.keys(config.academicLevel).map((key) => {
      return { id: Number(key), name: config.academicLevel[key] };
    });

    columnSources['secondaryPaperType'] = Object.keys(config.secondaryPaperType).map((key) => {
      return { id: Number(key), name: config.secondaryPaperType[key] };
    });

    columnSources['academicSessionId'] = this._academiaService.getAcademicSession().map((x: any) => {
      return { id: x.id, name: x.name };
    });

    columnSources['disciplineId'] = this._academiaService.getDisciplines().map((x: any) => {
      return { id: x.id, name: x.name };
    });

    var arrangedData = results.map((value: any) => {
      var item = {};
      for (var key in fieldMaps) {
        var valueWidth = (value[fieldMaps[key]] + '').length * 15;
        item[key] = value[fieldMaps[key]];
        if (!columnsWidth[key] || columnsWidth[key] < valueWidth) {
          columnsWidth[key] = valueWidth;
        }
      }

      return item;
    });



    this._uiService.replaceCsvValueWithId(columnSources, arrangedData);
    this._uiService.createDataPreviewColumns(fieldMaps, columnsWidth, columns, columnSources);
    columnsWidth = null;
    this._uiService.indeterminateProgressTextSet.next(null);

    this._uiService.openDataPreviewWindow(arrangedData, columns, [
      {
        type: 'i',
        content: 'done_all',
        onclick: () => {
          this.onImportPreviewCompleted(Object.keys(fieldMaps), arrangedData, columns);
        }
      }
    ]);
  }

  onImportPreviewCompleted(fields: any, arrangedData: any, columns: any) {
    var data = this._uiService.getPreviewModifiedData();
    var converted = data.map((value: any) => {
      var item = {};
      for (var i = 0; i < fields.length; i++) {
        item[fields[i]] = value[i];
      }
      return item;
    });
    this._uiService.closeDataPreview();
    var worker = new Worker('../workers/import-marks.worker', { name: 'marks-worker', type: 'module' });

    this._uiService.startWorker(worker, { values: converted, paperId: this.paper.id, examiner: this.examiner, token: sessionStorage.getItem("token") })
      .then(() => {
        //show success message, if required
        window.location.href = "/paper/" + this.paper.id;
      }).catch(e => {
        if (e.validation) {
          this.onImportValidationError(e.validation, converted, columns, fields);
        }
      });
  }

  onImportValidationError(validation: any, converted: any, columns: any, fields: any) {
    this._uiService.onDataPreviewReady = () => {
      var allFields = Object.keys(converted[0]);
      for (var i = 0; i < validation.length; i++) {
        var commonErrors = [];
        for (var field in validation[i]) {
          var fieldIndex = allFields.indexOf(field);
          if (fieldIndex <= 0) {
            commonErrors = commonErrors.concat(validation[i][field]);
          } else {
            this._uiService.setPreviewDataCellCommentAndStyle(i + 1, fieldIndex + 1, validation[i][field].join('\n'), null);
          }
        }
        if (commonErrors.length > 0) {
          this._uiService.setPreviewDataCellCommentAndStyle(i + 1, 1, commonErrors.join('\n'), null);
        }
      }
    };
    this._uiService.openDataPreviewWindow(converted, columns, [
      {
        type: 'i',
        content: 'done_all',
        onclick: () => {
          this.onImportPreviewCompleted(fields, converted, columns);
        }
      }
    ]);
  }

  mapCsvField(results: any) {
    this._uiService
      .mapCsvField(results.meta.fields, this.marksEntryHeader)
      .then((maps: any) => {
        this.previewCsvData(maps, results.data);
      }).catch(() => { });
  }

  uploadFile(fileEle: any, examiner: any) {
    this.examiner = examiner;
    this._uiService
      .parseCsv(fileEle.files[0])
      .then((results: any) => {
        fileEle.value = null;
        this.mapCsvField(results);
      }).catch(() => {
        fileEle.value = null;
      });
  }

  downloadCsv(examinationId: any, paperId: any, evaluatorId: any, mode: any, term: any) {
    this._examService.downloadSubmitMarks(examinationId, paperId, evaluatorId, mode, term).subscribe((result: any) => {
      var students = [];
      for (var i = 0; i < result.length; i++) {
        /* var term = "";
        if (result[i].isMidTermMarks) {
          term = "Mid";
        } else {
          term = "End";
        } */
        var studentMarks = "";
        if (result[i].studentAbsent) {
          studentMarks = "Ab";
        } else {
          studentMarks = result[i].marks;
        }

        students.push({
          registrationNumber: result[i].student.registrationNumber,
          batchStartYear: result[i].student.batchStartYear,
          code: result[i].paper.code,
          semester: result[i].paper.semester,
          session: result[i].evaluator.paperSessionName,
          examinerName: result[i].evaluator.examinerName,
          examinationName: result[i].examinationName,
          marks: studentMarks,
          mode: config.evaluatorType[result[i].mode],
          term: term
        });
      }
      this.students = students;
      setTimeout(() => {
        this._exportAsService.save({ type: "csv", elementId: "studentMarks" }, this.students[0].code + "(" + this.students[0].session + ")").subscribe(() => {
        });
      }, 10);
    })
  }

  addExaminer(type: number) {
    this._router.navigate([{
      outlets: { rightMainPopup: ['examiner-list', { id: this.paper.disciplineId, type: type, paperId: this.paper.id, examId: this.activeExam.id }] }
    }]);
  }

  removeEmp(item: any) {
    this.selectedExaminerArray.splice(this.selectedExaminerArray.indexOf(item), 1);
  }

  removeReviewer(item: any) {
    this.selectedReviewerArray.splice(this.selectedReviewerArray.indexOf(item), 1);
  }
  removeThirdExaminer(item: any) {
    this.selectedThirdExaminerArray.splice(this.selectedThirdExaminerArray.indexOf(item), 1);
  }
  async saveReviewer() {
    await this.saveData(this.selectedReviewerArray, 4);
  }
  async saveExaminer() {
    await this.saveData(this.selectedExaminerArray, 2);
  }
  async saveThirdExaminer() {
    await this.saveData(this.selectedThirdExaminerArray, 104);
  }

  async saveData(arrayData: any, type: number) {
    var temp = [];
    temp = temp.concat(this.selectedEvaluators);
    var endMarks = 0;
    this.session = this._academiaService.getSessionDetails(this.paperDetails.academicSessionId);
    var update = await arrayData.forEach(async (data: any, index: any) => {
      let submit = {
        paperId: this.paperDetails.id,
        paperCode: this.paperDetails.code,
        paperName: this.paperDetails.name,
        paperSessionId: this.paperDetails.academicSessionId,
        paperSessionName: this.session.name,
        examinationId: this.activeExam.id,
        examinerId: data.item.id,
        examinerName: data.item.name,
        type: type,
        alocatedEndTermMarks: Number(data.uploadedEndMarks),
        allowSubmitMidTermMarks: data.uploadedMidMarks,
        midTermMarksSubmitedOn: '',
        endTermMarksSubmitedOn: '',
        allowSubmitEndTermMarks: data.uploadedEndTermMarks
      }
      temp.push(submit);
      endMarks = endMarks + Number(data.uploadedEndMarks);
    });
    if (this.paperDetails.endTermAllocatedMarks < endMarks) {
      this._tosterService.error("Allocated End term marks is too large");
      return;
    }
    var dataSubmit = await this._examService.setEvaluators(temp).toPromise();
    this.selectExam(this.activeExam.id);
    if (type == 2) {
      this.selectedExaminerArray = [];
      this._tosterService.success("Successfully allocated examiner");
    } else if (type == 4) {
      this.selectedReviewerArray = [];
      this._tosterService.success("Successfully allocated reviewer");
    } else {
      this.selectedThirdExaminerArray = [];
      this._tosterService.success("Successfully allocated third examiner");
    }

  }

  async removeData(item: any) {
    this.selectedEvaluators.splice(this.selectedEvaluators.indexOf(item), 1);
    var dataSubmit = await this._examService.setEvaluators(this.selectedEvaluators).toPromise();
    this.selectExam(this.activeExam.id);
  }

  selectedItem(employ: any, i: number) {
    const midTerm = this.examiners.filter((x: any) => x.allowSubmitMidTermMarks);
    if (midTerm.length == 0) {
      this.selectedExaminerArray.forEach(async (data: any, index: any) => {
        data.uploadedMidMarks = false;
      });
      this.selectedExaminerArray[i]['uploadedMidMarks'] = true;
    } else {
      setTimeout(() => {
        this.selectedExaminerArray[i]['uploadedMidMarks'] = false;
      }, 100);
      this._tosterService.error("Midterm coordinator is already allocated");
    }

  }

  selectedEndTermItem(employ: any, i: number) {
    const endTerm = this.examiners.filter((x: any) => x.allowSubmitEndTermMarks);
    if (endTerm.length == 0) {
      this.selectedExaminerArray.forEach(async (data: any, index: any) => {
        data.uploadedEndTermMarks = false;
      });
      this.selectedExaminerArray[i]['uploadedEndTermMarks'] = true;
    } else {
      setTimeout(() => {
        this.selectedExaminerArray[i]['uploadedEndTermMarks'] = false;
      }, 100);
      this._tosterService.error("Endterm coordinator is already allocated");
    }
  }


}
