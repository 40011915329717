<ng-template #na><span> NA</span></ng-template>
<form [formGroup]="paperForm">
    <div class="p-3 vw-25" style="overflow-y: auto;">
        <!-- <div class="d-flex icon-set justify-content-around p-2 mb-3">

            <div (click)="editPaper()" *ngIf="!editMode" class="text-default">
                <em class="fa fa-pencil"></em>
                {{ 'Edit' | translate }}
            </div>
            <div *ngIf="editMode" class="text-default">
                <button class="btn text-100 p-0 z-depth-0 text-default"><em class="fa fa-save"></em>
                    <span>{{ 'Save' | translate }}</span></button>
            </div>
        </div> -->
        <div class="scroll_div p-2 mb-3">
            <div class="h4">
                <textarea class="w-100 form-control" rows="3" disabled="editMode" type="text" formControlName="name"></textarea>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Group' | translate }}:</div>
                <div class=""><input type="text" disabled="editMode" class="w-100  form-control"
                        formControlName="groupCode"></div>

            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Codes' | translate }}:</div>
                <div class=""><input disabled="editMode" type="text" class="w-100  form-control" formControlName="code">
                </div>

            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Session' | translate }}:</div>
                <div class=""><select formControlName="academicSessionId" disabled="editMode" class="form-control">
                        <option value="">{{ 'Select Session' | translate }}</option>
                        <option *ngFor="let session of sessions" [value]="session.id">{{session.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Discipline' | translate }}:</div>
                <div class=""><select formControlName="disciplineId" disabled="editMode" class="form-control">
                        <option value="">{{ 'Select Discipline' | translate }}</option>
                        <option *ngFor="let discipline of disciplines" [value]="discipline.id">{{discipline.name}}
                        </option>
                    </select>

                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Academic level' | translate }}:</div>
                <div class=""><select formControlName="level" disabled="editMode" class="form-control">
                        <option value="">{{ 'Select academic level' | translate }}</option>
                        <option *ngFor="let academiclevel of env.academicLevel | keyvalue" [value]="academiclevel.key">
                            {{academiclevel.value}}</option>
                    </select>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Paper Type' | translate }}:</div>
                <div class=""><select disabled="editMode" formControlName="paperType" class="form-control">
                        <option value="">{{ 'Select paper Type' | translate }}</option>
                        <option *ngFor="let papertype of env.paperType | keyvalue" [value]="papertype.key">
                            {{papertype.value}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Secondery Paper Type' | translate }}:</div>
                <div class=""><select disabled="editMode" formControlName="secondaryPaperType" class="form-control">
                        <option value="">{{ 'Select paper Type' | translate }}</option>
                        <option *ngFor="let secondaryPaper of env.secondaryPaperType | keyvalue"
                            [value]="secondaryPaper.key">
                            {{secondaryPaper.value}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Semester' | translate }}:</div>
                <div class=""><input disabled="editMode" type="number" formControlName="semester"
                        class="w-100  form-control"></div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Full Marks' | translate }}:</div>
                <div class=""><input disabled="editMode" type="number" formControlName="fullMarks"
                        class="w-100  form-control"></div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Mid Term Allocated Marks' | translate }}:</div>
                <div class=""><input disabled="editMode" type="number" formControlName="midTermAllocatedMarks"
                        class="w-100  form-control"></div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'End Term Allocated Marks' | translate }}:</div>
                <div class=""><input disabled="editMode" type="number" formControlName="endTermAllocatedMarks"
                        class="w-100  form-control"></div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Credit' | translate }}:</div>
                <div class=""><input type="number" disabled="editMode" formControlName="credit"
                        class="w-100  form-control"></div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Mid Term Credit' | translate }}:</div>
                <div class=""><input disabled="editMode" type="number" formControlName="midTermCredit"
                        class="w-100  form-control"></div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'End Term Credit' | translate }}:</div>
                <div class=""><input disabled="editMode" type="number" formControlName="endTermCredit"
                        class="w-100  form-control"></div>

            </div>
        </div>
    </div>
    <input type="hidden" formControlName="id">
</form>
