/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./home.component";
import * as i5 from "../ui.service";
import * as i6 from "../api-client/utility.service";
var styles_HomeComponent = [i0.styles];
var RenderType_HomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
export function View_HomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "home_section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "flex-grow-1 m-4 configure_list d-flex text-80 font-weight-500"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "w-50 mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "configure_button mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "a", [["class", "btn text-100 font-weight-500 z-depth-0 mr-2 w-100"], ["routerLink", "/examiner-allocation"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { "btn-primary": 0, "disabled": 1 }), i1.ɵdid(8, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Paper Configure"])), (_l()(), i1.ɵeld(10, 0, null, null, 7, "div", [["class", "w-50"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 6, "div", [["class", "configure_button mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 5, "a", [["class", "btn btn-secondary text-100 font-weight-500 z-depth-0 w-100"], ["routerLink", "/evaluter-papers"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(14, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(15, { "btn-primary": 0, "disabled": 1 }), i1.ɵdid(16, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Marks Capture"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "btn text-100 font-weight-500 z-depth-0 mr-2 w-100"; var currVal_3 = _ck(_v, 7, 0, _co.allowPaperConfiguration, !_co.allowPaperConfiguration); _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_4 = "/examiner-allocation"; _ck(_v, 8, 0, currVal_4); var currVal_7 = "btn btn-secondary text-100 font-weight-500 z-depth-0 w-100"; var currVal_8 = _ck(_v, 15, 0, _co.allowMarksSubmission, !_co.allowMarksSubmission); _ck(_v, 14, 0, currVal_7, currVal_8); var currVal_9 = "/evaluter-papers"; _ck(_v, 16, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8).target; var currVal_1 = i1.ɵnov(_v, 8).href; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_5 = i1.ɵnov(_v, 16).target; var currVal_6 = i1.ɵnov(_v, 16).href; _ck(_v, 12, 0, currVal_5, currVal_6); }); }
export function View_HomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i1.ɵdid(1, 114688, null, 0, i4.HomeComponent, [i5.UiService, i6.UtilityService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeComponentNgFactory = i1.ɵccf("app-home", i4.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
