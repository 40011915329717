import { Component, OnInit } from '@angular/core';
import { AcademiaService } from '../api-client/academia.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../ui.service';
import { ExportAsService } from 'ngx-export-as';
import { ToastrService } from 'ngx-toastr';
import { paperService } from '../api-client/paper.service';

@Component({
  selector: 'app-search-students',
  templateUrl: './search-students.component.html',
  styleUrls: ['./search-students.component.css']
})
export class SearchStudentsComponent implements OnInit {

  paperId: any;
  academicSessionId: any;
  currentSemester: any;
  students: any;
  freeText: any;
  studentsInpaper: any;
  paper:any;

  constructor(
    private _academiaService: AcademiaService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _uiService: UiService,
    private _paperService: paperService,
    private _exportAsService: ExportAsService,
    private _tosterService: ToastrService) {

    this._route.params.subscribe((args: any) => {
      this.paperId = args.id;
      this.studentsInPaper();
      this.paperDetails();
    });
  }

  ngOnInit() {
  }

  paperDetails() {
    this._paperService.getPaper(this.paperId).subscribe((result: any) => {
      this.paper = result;
      this.academicSessionId = result.academicSessionId;
      this.currentSemester = result.semester;
    })
  }

  studentSearch() {
    if (this.freeText == "" || this.freeText == null) {
      this._tosterService.error("Student reg.no or name can not be blank.");
      return;
    }
    var filters = {
      "freeText": this.freeText,
      "academicSessionId": this.academicSessionId,
      "programIds": [],
      "semester": this.currentSemester,
      "batchStartYear": 0,
      "registrationYear": 0,
      "startIndex": 0,
      "pageSize": 0
    };
    this._academiaService.students(filters).subscribe((result: any) => {
      for (var i = 0; i < result.items.length; i++) {
        var student = this.studentsInpaper.filter((x: any) => x.id == result.items[i].id);
        if (student.length > 0) {
          result.items[i].assigned = true;
        }
      }
      this.students = result.items;
    });
  }

  async studentsInPaper() {
    await this._academiaService.getPaperWiseStudents(this.paperId).toPromise().then((result: any) => {
      this.studentsInpaper = result;
    })
  }

  assignStudent(student: any) {
    var filter = {
      "studentId": student.id,
      "paperCodes": [this.paper.code]
    }
    this._academiaService.setStudentPaper(filter).subscribe((result:any)=>{
      this._tosterService.success("Student set in this paper");
      this.studentsInPaper();
      this.studentSearch();
      return;
    })
  }

}
