import { Component, OnInit } from '@angular/core';
import { UiService } from '../ui.service';
import { UtilityService } from '../api-client/utility.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  allowPaperConfiguration: boolean = false;
  allowMarksSubmission: boolean = false;
  constructor(
    private _uiService:UiService,
    private _utilityService: UtilityService
  ) {
    this._uiService.changeAppTitle.next("Dashboard");
   }

  ngOnInit() {
    this._uiService.changeAppTitle.next("Dashboard");
    this.myActiveRequest();
  }

  myActiveRequest(){
    this._utilityService.myActiveRequerst().subscribe((result:any)=>{
      this.allowPaperConfiguration = result.filter(x=>x.type==2).length>0;
      this.allowMarksSubmission = result.filter(x=>x.type==4 || x.type==8).length>0;
    })
  }

}
