import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { config } from '../config';
import { StudentModel } from '../models/student.model';
import { map } from 'rxjs/operators';
import { StaffModel } from '../models/staff.model';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  private _employees: any;

  public onStudentsUpdated = new Subject();

  constructor(private _http: HttpClient) {

  }

  public getEmployeess() {
    return this._employees;
  }

  public getEmployee(id: any) {
    if (this._employees == null) return null;
    var items = this._employees.filter((x: any) => x.id == id);
    if (items.length > 0) {
      return items[0];
    } else {
      return null;
    }
  }

  resetPassword(email: string) {
    var data = { email: email };
    return this._http
      .post(config.apiEndPointBase + "auth/reset-password", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  auth(data: any) {
    return this._http
      .post(config.apiEndPointBase + "auth/validate-join-date", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  emailExists(data: any) {
    return this._http
      .post(config.apiEndPointBase + "auth/email-exists", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  saveEmployee(data: StaffModel): Observable<StaffModel> {
    if (data.id) {
      return this._http
        .put(config.apiEndPointBase + "employee", data, {})
        .pipe(map((response: any) => {
          return new StaffModel().deserialize(response);
        }));
    } else {
      return this._http
        .post(config.apiEndPointBase + "employee", data, {})
        .pipe(map((response: any) => {
          return new StaffModel().deserialize(response);
        }));
    }
  }

}
