<div class="student_details_section d-flex">
    <div class="left_bg w-25">
        <app-paper-summary [displayDetailIcon]="false"></app-paper-summary>
    </div>
    <div class="p-4 w-75">
        
        <h4 *ngIf="discipline && session">{{discipline.name}} ({{session.name}})</h4>
        
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="examiners-tab" data-toggle="tab" href="#examiners" role="tab"
                    aria-controls="examiners" aria-selected="true">{{ 'Examiners & Reviewers' | translate }}</a>
            </li>
            <!-- <li class="nav-item">
                <a class="nav-link" id="studentsList-tab" data-toggle="tab" href="#studentsList" role="tab"
                    aria-controls="studentsList" aria-selected="false">{{ 'Students' | translate }}</a>
            </li> -->
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="examiners" role="tabpanel" aria-labelledby="examiners-tab">
                <app-examiners [paper]="paper" ></app-examiners>
            </div>
            <!-- <div class="tab-pane fade" id="studentsList" role="tabpanel" aria-labelledby="studentsList-tab">
                <app-paper-wise-students [paperId]="paperId" ></app-paper-wise-students>
            </div> -->
        </div>
    </div>
</div>