<ng-template #notactivesession><span class="font-weight-600 d-block blue-grey lighten-5 p-2 text-100 text-center">Please
    Select Active Session Paper</span>
</ng-template>
<ng-template #nodatafound><span class="font-weight-600 d-block blue-grey lighten-5 p-2 text-100 text-center">No Result
    Found</span></ng-template>
<div class="examiner_section mt-3">
  <div class="d-flex justify-content-first">
    <!-- <div class="text-100 font-weight-500"><span>Selected session :</span> <span class="font-weight-600"> {{sessionId.name}}</span></div> -->
    <div class="">
      <select (change)="selectExam($event.target.value)" class="form-control" disabled>
        <option value="">{{ 'Select Exam' | translate }}</option>
        <option *ngFor="let exam of exams" [value]="exam.id" [selected]="exam.id==activeExam.id">{{exam.name}}
        </option>
      </select>
    </div>
  </div>

  <div *ngIf="!showPaperConfiguration">
    <div class="sec_row mt-3">

      <h5 class="h5 font-weight-600"> <a class="text-color text-22px" (click)="addExaminer(1)">
          <em class="fa fa-plus-square-o"></em>
        </a> <a *ngIf="selectedExaminerArray.length>0" class="text-color text-22px ml-3" (click)="saveExaminer()">
          <em class="fa fa-save"></em>
        </a> <span class="ml-2">{{ 'Examiners' | translate }}</span> </h5>
      <div class="row_color p-2 text-100 font-weight-500">
        <div class="row">
          <div class="col-sm-3">
            {{ 'Examiner Name' | translate }}
          </div>
          <div class="col-sm-5 ">
            Marks Coordinator
          </div>

          <div class="col-sm-3">
            {{ 'Allocated Endterm Marks' | translate }}
          </div>
          <div class="col-sm-1">

          </div>

          <!-- <div class="col-sm-4">
                        {{ 'Upload Endterm Marks' | translate }}
                    </div> -->
        </div>
      </div>
      <ng-container *ngIf="selectedExaminerArray.length>0">
        <div *ngFor="let employ of selectedExaminerArray; let i = index;" class="row mt-1">
          <div class="col-sm-3">
            {{employ?.item?.name}}
          </div>
          <!-- <div class="col-sm-2">
                        <input type="text" [(ngModel)]="employ.allocatedMarks" class="form-control p-2"
                            placeholder="Allocated Marks">
                    </div> -->
          <div class="col-sm-5">
            <input type="checkbox" (click)="selectedItem(employ,i)" [(ngModel)]="employ.uploadedMidMarks"
              class="m-2" />Midterm
            <input type="checkbox" (click)="selectedEndTermItem(employ,i)" [(ngModel)]="employ.uploadedEndTermMarks"
              class="m-2" />Endterm
          </div>
          <!-- <div class="col-sm-3">

                    </div> -->
          <div class="col-sm-3">
            <input type="text" [(ngModel)]="employ.uploadedEndMarks" class="form-control p-2"
              placeholder="Uploaded End Marks">
          </div>
          <div class="col-sm-1 d-flex justify-content-center align-items-center"><i (click)="removeEmp(employ)"
              class='text-danger fa fa-trash'></i></div>
        </div>


      </ng-container>

      <ng-container *ngIf="examiners.length>0; else nodatafound">
        <div class="white p-2 text-100 font-weight-500">
          <div *ngFor="let examiner of examiners" class="row">
            <div class="col-sm-3">
              {{examiner.examinerName}}
            </div>

            <div class="col-sm-5">
              <span *ngIf="examiner.allowSubmitMidTermMarks" class="m-2"> <i class="fa fa-check-circle text-success text-22"></i> Mid-Term</span>
              <!-- <span *ngIf="!examiner.allowSubmitMidTermMarks" class="m-2">Midterm No</span> -->

              <span *ngIf="examiner.allowSubmitEndTermMarks" class="m-2"><i class="fa fa-check-circle text-success text-22"></i> End-Term </span>
              <!-- <span *ngIf="!examiner.allowSubmitEndTermMarks" class="m-2">Endterm No</span> -->

            </div>
            <!-- <div class="col-sm-3">


                      </div> -->
            <div class="col-sm-3">
              {{examiner.alocatedEndTermMarks}}

            </div>
            <div class="col-sm-1 d-flex justify-content-center align-items-center"><i (click)="removeData(examiner)"
                class='text-danger fa fa-trash'></i></div>
            <!-- <div class="col-sm-4">
                            <ng-container>
                                <div class="form-group m-0 mr-2 d-flex font-weight-500">
                                    <em class="fa fa-upload position-relative ml-2 p-2 rounded-circle">
                                        <input type="file" (change)="uploadFile($event.target,examiner)"
                                            class="hidden-file" id="importStudents">
                                    </em>
                                    <label for="importStudents" class="m-0">{{ 'Import' | translate }}</label>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="examiner.endTermMarksSubmitedOn">
                                Import on {{examiner.endTermMarksSubmitedOn | date:'dd-MMM-yyyy'}} <em
                                    (click)="downloadCsv(examiner.examinationId,examiner.paperId,examiner.examinerId,examiner.type,'end')"
                                    class="text-primary fa fa-download"></em>
                            </ng-container>
                        </div> -->
          </div>
        </div>
      </ng-container>
    </div>
    <div class="sec_row mt-3">
      <h5 class="h5 font-weight-600"><a class="text-color text-22px" (click)="addExaminer(2)">
          <em class="fa fa-plus-square-o"></em>
        </a> <a *ngIf="selectedReviewerArray.length>0" class="text-color text-22px ml-3" (click)="saveReviewer()">
          <em class="fa fa-save"></em>
        </a><span class="ml-2">{{ 'Reviewers' | translate }}</span></h5>
      <div class="row_color p-2 text-100 font-weight-500">
        <div class="row">
          <div class="col-sm-4">
            {{ 'Reviewer Name' | translate }}
          </div>
          <div class="col-sm-4">

                      </div>
                     <div class="col-sm-4">
                         {{ 'Allocated Marks' | translate }}
                     </div>

        </div>
      </div>
      <ng-container *ngIf="selectedReviewerArray.length>0">
        <div *ngFor="let employ of selectedReviewerArray; let i = index;" class="row mt-1">
          <div class="col-sm-4">
            {{employ?.item?.name}}
          </div>

          <div class="col-sm-4">
                        <!-- <input type="checkbox" [(ngModel)]="employ.uploadedMidMarks" class="mr-2" /> -->
                    </div>
                    <div class="col-sm-3">
                        <input type="text" [(ngModel)]="employ.uploadedEndMarks" class="form-control p-2"
                            placeholder="Uploaded End Marks">
                    </div>
          <div class="col-sm-1 d-flex justify-content-center align-items-center"><i (click)="removeReviewer(employ)"
              class='text-danger fa fa-trash'></i></div>
        </div>


      </ng-container>
      <ng-container *ngIf="reviewers.length>0; else nodatafound">
        <div class="white p-2 text-100 font-weight-500">
          <div *ngFor="let reviewer of reviewers" class="row">
            <div class="col-sm-4">
              {{reviewer.examinerName}}
            </div>
            <div class="col-sm-4"></div>
            <div class="col-sm-3">
              {{reviewer.alocatedEndTermMarks}}

            </div>
            <div class="col-sm-1 d-flex justify-content-center align-items-center"><i (click)="removeData(reviewer)"
              class='text-danger fa fa-trash'></i></div>
            <!-- <div class="col-sm-4">
              <span *ngIf="reviewer.allowSubmitMidTermMarks">Yes</span>
              <span *ngIf="!reviewer.allowSubmitMidTermMarks">No</span>

            </div> -->
            <!-- <div class="col-sm-4">
              {{reviewer.alocatedEndTermMarks}}
            </div> -->
            <!-- <div class="col-sm-3">
                            {{reviewer.midTermMarksSubmitedOn | date:'dd/MM/yyy'}}
                        </div> -->
            <!-- <div class="col-sm-3">
                            <ng-container>
                                <div class="form-group m-0 mr-2 d-flex font-weight-500">
                                    <em class="fa fa-upload position-relative ml-2 p-2 rounded-circle">
                                        <input type="file" (change)="uploadFile($event.target,reviewer)"
                                            class="hidden-file" id="importStudents">
                                    </em>
                                    <label for="importStudents" class="m-0">{{ 'Import' | translate }}</label>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="reviewer.endTermMarksSubmitedOn">
                                Import on {{reviewer.endTermMarksSubmitedOn | date:'dd-MMM-yyyy'}} <em
                                    (click)="downloadCsv(reviewer.examinationId,reviewer.paperId,reviewer.examinerId,reviewer.type,'end')"
                                    class="text-primary fa fa-download"></em>
                            </ng-container>
                        </div> -->
          </div>
        </div>
      </ng-container>
    </div>
    <div class="sec_row mt-3">
      <h5 class="h5 font-weight-600"><a class="text-color text-22px" (click)="addExaminer(104)">
          <em class="fa fa-plus-square-o"></em>
        </a> <a *ngIf="selectedThirdExaminerArray.length>0" class="text-color text-22px ml-3" (click)="saveThirdExaminer()">
          <em class="fa fa-save"></em>
        </a><span class="ml-2">Third Examiner</span></h5>
      <div class="row_color p-2 text-100 font-weight-500">
        <div class="row">
          <div class="col-sm-4">
            Third Examiner Name
          </div>
          <div class="col-sm-4">
                        <!-- {{ 'Allow Midterm Marks Submission' | translate }} -->
                      </div>
                     <div class="col-sm-3">
                         {{ 'Allocated Marks' | translate }}
                     </div>
                     <div class="col-sm-1">

                    </div>

        </div>
      </div>
      <ng-container *ngIf="selectedThirdExaminerArray.length>0">
        <div *ngFor="let employ of selectedThirdExaminerArray; let i = index;" class="row mt-1">
          <div class="col-sm-4">
            {{employ?.item?.name}}
          </div>
          <div class="col-sm-4">
            <!-- <input type="checkbox" [(ngModel)]="employ.uploadedMidMarks" class="mr-2" /> -->
        </div>
        <div class="col-sm-3">
            <input type="text" [(ngModel)]="employ.uploadedEndMarks" class="form-control p-2"
                placeholder="Uploaded End Marks">
        </div>

          <div class="col-sm-1 d-flex justify-content-center align-items-center"><i (click)="removeThirdExaminer(employ)"
              class='text-danger fa fa-trash'></i></div>
        </div>


      </ng-container>
      <ng-container *ngIf="thirdExaminer.length>0; else nodatafound">
        <div class="white p-2 text-100 font-weight-500">
          <div *ngFor="let third of thirdExaminer" class="row">
            <div class="col-sm-4">
              {{third.examinerName}}
            </div>
            <div class="col-sm-4"></div>
            <div class="col-sm-3">
              {{third.alocatedEndTermMarks}}

            </div>
            <div class="col-sm-1 d-flex justify-content-center align-items-center"><i (click)="removeData(third)"
              class='text-danger fa fa-trash'></i></div>

          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div *ngIf="showPaperConfiguration">
    <ul class="nav nav-tabs mt-2" id="myTab" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" id="examiner-tab" data-toggle="tab" href="#examiner" role="tab"
          aria-controls="examiner" aria-selected="true">{{ 'Examiners' | translate }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="reviewersList-tab" data-toggle="tab" href="#reviewersList" role="tab"
          aria-controls="reviewersList" aria-selected="false">{{ 'Reviewers' | translate }}</a>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="examiner" role="tabpanel" aria-labelledby="examiner-tab">
        <div class="select_examiner d-flex justify-content-start mt-2 mb-3">
          <div class="mr-3 d-flex align-items-center">
            <input class="mr-1" type="radio" (click)="addExamRow(1)" name="selectexaminer" id="singleExaminer">
            <label class="m-0 font-weight-500" for="singleExaminer">{{ 'Single Examiner' | translate
              }}</label>
          </div>
          <div class="d-flex align-items-center">
            <input class="mr-1" type="radio" (click)="addExamRow(2)" name="selectexaminer" id="multipleExaminer">
            <label class="m-0 font-weight-500" for="multipleExaminer">{{ 'Multiple Examiner' | translate
              }}</label>
          </div>
        </div>
        <div class="grey lighten-5 border rounded">
          <div class="number_row d-flex align-items-center p-2 grey lighten-3 border">
            <h6 class="h6 m-0 mr-4">{{ 'No. of Examiner' | translate }}</h6>
            <input type="text" [(ngModel)]="numberOfExaminer" (change)="updateExaminer()" class="form-control w-auto">
            <button class="btn btn-primary font-weight-500 text-100 p-1 pr-2 pl-2 ml-2"><em class="fa fa-arrow-right"
                aria-hidden="true"></em></button>
          </div>
          <div class="pl-3 pr-3">
            <div *ngIf="examinerData.length>0" class="d-flex align-items-center">
              <div>Name</div>
              <div>End Term Marks</div>
              <div>Mid Term Marks</div>
            </div>
            <div *ngFor="let examiner of examinerData;let i=index" class="row p-2 border-bottom align-items-center">
              <div class="col-sm-4 d-flex align-items-center">
                <!-- <div class="serial_no h4 m-0 widthnumber mr-2">
                            </div> -->
                <div class="examiner_select w-100">
                  <select [(ngModel)]="examiner.examinerId" class="form-control">
                    <option>{{ 'Select Examiner' | translate }}</option>
                    <option *ngFor="let employee of employees;" [value]="employee.id">
                      {{employee.name}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sm-2">
                <input type="text" [(ngModel)]="examiner.alocatedEndTermMarks" placeholder="Marks" class="form-control">
              </div>
              <div class="col-sm-1">
                <input type="radio" (click)="radioVal('exam',i)" [value]="i" name="exam">
              </div>
              <input type="hidden" value="2" [(ngModel)]="examiner.type">
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="reviewersList" role="tabpanel" aria-labelledby="reviewersList-tab">
        <div class="select_examiner d-flex justify-content-start mt-2 mb-3">
          <div class="mr-3 d-flex align-items-center">
            <input class="mr-1" type="radio" (click)="addRevRow(1)" name="singleReviewer" id="singleReviewer">
            <label class="m-0 font-weight-500" for="singleExaminer">{{ 'Single Reviewer' | translate
              }}</label>
          </div>
          <div class="d-flex align-items-center">
            <input class="mr-1" type="radio" (click)="addRevRow(2)" name="singleReviewer" id="singleReviewer">
            <label class="m-0 font-weight-500" for="multipleExaminer">{{ 'Multiple Reviewer' | translate
              }}</label>
          </div>
        </div>
        <div class="grey lighten-5 border rounded">
          <div class="number_row d-flex align-items-center p-2 grey lighten-3 border">
            <h6 class="h6 m-0 mr-4">{{ 'No. of Reviewer' | translate }}</h6>
            <input type="text" [(ngModel)]="numberOfReviewer" (change)="updateReviewer()" class="form-control w-auto">
            <button class="btn btn-primary font-weight-500 text-100 p-1 pr-2 pl-2 ml-2"><em class="fa fa-arrow-right"
                aria-hidden="true"></em></button>
          </div>
          <div class="pl-3 pr-3">
            <div *ngIf="reviewerData.length>0" class="d-flex align-items-center">
              <div>Name</div>
              <div>End Term Marks</div>
              <div>Mid Term Marks</div>
            </div>
            <div *ngFor="let reviewer of reviewerData;let i=index" class="row p-2 border-bottom align-items-center">
              <div class="col-sm-4 d-flex align-items-center">
                <!-- <div class="serial_no h4 m-0 widthnumber mr-2">
                            </div> -->
                <div class="examiner_select w-100">
                  <select [(ngModel)]="reviewer.examinerId" class="form-control">
                    <option>{{ 'Select Reviewer' | translate }}</option>
                    <option *ngFor="let employee of employees;" [value]="employee.id">
                      {{employee.name}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sm-2">
                <input type="text" [(ngModel)]="reviewer.alocatedEndTermMarks" placeholder="Marks" class="form-control">
              </div>
              <div class="col-sm-1">
                <!-- <input type="radio" (click)="radioVal('rev',i)" [value]="i" name="rev"> -->
              </div>
              <input type="hidden" value="1" [(ngModel)]="reviewer.type">
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn-primary mt-2 text-center" (click)="save()">Save</button>
  </div>
  <br>
  <app-paper-wise-students [paper]="paper"></app-paper-wise-students>
</div>
<div class="d-none">
  <table id="studentMarks">
    <tr>
      <th>Session</th>
      <th>Paper Code</th>
      <th>Examination</th>
      <th>Examiner</th>
      <th>Mode</th>
      <th>Term</th>
      <th>Reg. No.</th>
      <th>Batch</th>
      <th>Marks Obtained</th>
    </tr>
    <tr *ngFor="let student of students">
      <td>{{student.session}}</td>
      <td>{{student.code}}</td>
      <td>{{student.examinationName}}</td>
      <td>{{student.examinerName}}</td>
      <td>{{student.mode}}</td>
      <td>{{student.term}}</td>
      <td>{{student.registrationNumber}}</td>
      <td>{{student.batchStartYear}}</td>
      <td>{{student.marks}}</td>
    </tr>
  </table>
</div>
