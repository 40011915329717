import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Papa } from 'ngx-papaparse';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  showHeader = new Subject<boolean>();
  changeAppTitle = new Subject<string>();
  detailOutletUpdateForId = new Subject<string>();
  quickItemsSet = new Subject<string>();
  indeterminateProgressTextSet = new Subject<string>();
  closeDataPreview:any;
  previewData:any;
  getPreviewModifiedData:any;
  setPreviewDataCellCommentAndStyle:any;
  onDataPreviewReady:any;

  papereSet = new Subject<string>();
  paperInDisciplineSession:any;


  csvHeaders: any;
  csvTargetFields: any;
  afterCsvMapDone: any;

  closeCenterPopup:any;

  constructor(
    private _toster: ToastrService,
    private _router: Router,
    private _papa: Papa) { }

  setQuickItems(items: any) {
    this.quickItemsSet.next(items);
  }

  setpaper(item: any) {
    this.papereSet.next(item);
  }

  parseCsv(file: any): Promise<any> {
    var counter = 0;
    var items = [];
    var errors = [];
    var meta = null;
    this.indeterminateProgressTextSet.next('Reading csv...');
    return new Promise<any>((resolve, reject) => {

      this._papa.parse(
        file,
        {
          header: true,
          dynamicTyping: true,
          worker: true,
          delimiter: ',',
          skipEmptyLines: true,
          step: (results, parser) => {
            //console.log(results);           
            if (results.errors.length > 0) {
              errors.push(results.errors[0]);
              parser.abort();
              return;
            }

            items.push(results.data);
            if (meta == null) {
              meta = results.meta;
            }

            counter++;
            this.indeterminateProgressTextSet.next(`${counter} row(s) read from csv...`);
          },
          complete: () => {
            if (errors && errors.length > 0) {
              this._toster.error('Unable to parse this file. Please upload valid CSV. ' + errors[0].message);
              if(reject) reject(errors[0].message);
            } else {
              resolve({ data: items, errors: errors, meta: meta });
            }
            this.indeterminateProgressTextSet.next(null);
          }
        }
      );

    });
  }

  mapCsvField(headers: any, fields: any): Promise<any> {
    this.csvHeaders = headers;
    this.csvTargetFields = fields;
    return new Promise<any>((resolve, reject) => {
      this.afterCsvMapDone = (fieldMap: any) => {
        if(fieldMap.error){
          if(reject) reject(fieldMap.error);
        }else{
          resolve(fieldMap.maps);
        }
      };
      this._router.navigate([{
        outlets: { centerPopUp: ['data-import-mapper'] }
      }]);
    });
  }

  replaceCsvValueWithId(sources:any, data:any){
    for(var i=0; i<data.length; i++){
      for(var key in sources){
        if(data[i][key]){
          var values = sources[key].filter((x:any)=>x.name==data[i][key]);
          if(values.length>0){
            data[i][key] = values[0].id;
          }
        }
      }
    }
  }

  createDataPreviewColumns(fieldMaps:any, columnsWidth:any, columns:any, columnSources:any){
    for(var key in fieldMaps){
      var title = key;
      title = title.replace( /([A-Z])/g, " $1" );
      title = title.charAt(0).toUpperCase() + title.slice(1);
      if(columnsWidth[key]<title.length*15){
        columnsWidth[key] = title.length*15;
      }
      if(columnSources[key]){
        columns.push({
          title : title,
          type: 'dropdown',
          width : columnsWidth[key],
          source: columnSources[key]
        });
      }else{
        columns.push({
          title : title,
          width : columnsWidth[key],
        });
      }
    }
  }

  openDataPreviewWindow(data:any, columns:any, actionButtons:any)
  {
    this.previewData = {data:data, columns:columns, actionButtons: actionButtons};
    this._router.navigate(['data-preview']);
  }

  setIndeterminateProgressText(worker:Worker, text:any){
    this.indeterminateProgressTextSet.next(text);
  }

  showWorkerError(worker:Worker, error:any){
    this._toster.error(error);
    worker.terminate();
  }

  showWorkerCompleteMessage(worker:Worker, error:any){
    this._toster.success(error);
    worker.terminate();
  }

  startWorker(worker:Worker, initParams:any):Promise<any>
  {
    return new Promise((resolve, reject) => {
      worker.postMessage({method:'init', params:initParams});
      worker.onmessage = (event:any) => {
        var data = event.data;
        if(data.method && this[data.method]){
          this[data.method](worker, data.params);
          if(data.method=='showWorkerCompleteMessage'){
            resolve();
          }
        }else if(data.method && data.method=='errors'){
          reject(data.params);
          worker.terminate();
        }
      };
      worker.onerror = (event:any) => {
        worker.terminate();
      };
    });
    
  }
  
  setpaperInDisciplineSession(ele:any){
    this.paperInDisciplineSession = ele;
  }
}
