import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, of } from 'rxjs';
import { config } from '../../app/config';
import { DepartmentModel } from '../models/department.model';
import { map } from 'rxjs/operators';
import { CommonMethods } from '../common-methods';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  private _departments: any;

  public onDepartmentsUpdated = new Subject();

  constructor(private _http: HttpClient) {
    this.notifyDepartmentUpdate(null);
  }

  notifyDepartmentUpdate(departmentId: any) {
    this._http.get(config.apiEndPointBase + "organization/departments").subscribe((result: any) => {
      result.sort((x: any, y: any) => {
        return x.name.localeCompare(y.name);
      });
      this._departments = result;
      this.onDepartmentsUpdated.next(departmentId);
    });
  }

  public getDepartments() {
    return this._departments;
  }

  public getDepartment(id: any) {
    if (this._departments == null) return null;
    var items = this._departments.filter(x => x.id == id);
    if (items.length > 0) {
      return items[0];
    } else {
      return null;
    }
  }

  saveDepartment(data: DepartmentModel): Observable<DepartmentModel> {
    if (data.id) {
      return this._http
        .put(config.apiEndPointBase + "department", data, {})
        .pipe(map((response: any) => {
          return new DepartmentModel().deserialize(response);
        }));
    } else {
      return this._http
        .post(config.apiEndPointBase + "department", data, {})
        .pipe(map((response: any) => {
          return new DepartmentModel().deserialize(response);
        }));
    }

  }

  employees(data: any) {
    return this._http
      .post(config.apiEndPointBase + "organization/employees", data, {})
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  addStudent(student: any): Observable<any> {
    return new Observable((observer) => {
      setTimeout(() => {
        //console.log(student);
        observer.next({ me: 'hello' });
        observer.complete();
      }, 100);
    })
  }

  hods() {
    return this._http
      .post(config.apiEndPointBase + "organization/hods", {}, {})
      .pipe(map((response: any) => {
        response.sort((x: any, y: any) => {
          return x.name.localeCompare(y.name);
        });
        return response;
      }));
  }

  sendNotification(data: any) {
    return this._http
      .post(config.apiEndPointBase + "organization/employees", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  saveRole(data:any): Observable<any> {
    if (data.id) {
      return this._http
        .put(config.apiEndPointBase + "organization/role", data, {})
        .pipe(map((response: any) => {
          return response;
        }));
    } else {
      return this._http
        .post(config.apiEndPointBase + "organization/role", data, {})
        .pipe(map((response: any) => {
          return response;
        }));
    }
  }

  setRole(data: any) {
    return this._http
      .put(config.apiEndPointBase + "organization/role/"+data.roleId+"/set", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  unsetRole(data: any) {
    return this._http
      .put(config.apiEndPointBase + "organization/role/"+data.roleId+"/unset/"+data.employeeId, data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  getCapabilities() {
    return this._http.get(config.apiEndPointBase + "organization/capabilities", {});
  }

  getCapability(code: any) {
    return this._http.get(config.apiEndPointBase + "organization/capability/has/" + code, {});
  }

  getMyCapabilities() {
    return this._http.get(config.apiEndPointBase + "organization/capability/capabilities", {});
  }

  getRoles() {
    return this._http.get(config.apiEndPointBase + "organization/roles", {});
  }

  getRole(roleId:any) {
    return this._http.get(config.apiEndPointBase + "organization/role/"+roleId, {});
  }

  getMyRoles() {
    return this._http.get(config.apiEndPointBase + "organization/my-roles", {});
  }

  getEmployeeRoles(employeeId: any) {
    return this._http.get(config.apiEndPointBase + "organization/roles/" + employeeId, {});
  }
}
