import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { error } from 'protractor';
import { Router, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Injectable()
export class WebServiceInterceptor implements HttpInterceptor {
    activeTimer: any = null;
    isTabActive: boolean;
    counter = 0;
    checkInterval: any;
    constructor(
        public router: Router,
        private toastr: ToastrService) {

        $(document).mousemove((event: any) => {
            this.counter = 0;
            clearInterval(this.checkInterval);
            this.logout();
        });
    };

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {


        document.addEventListener('visibilitychange', (event) => {
            if (document.hidden) {
                this.counter = 0;
                clearInterval(this.checkInterval);
                this.logout();
            } else {
                this.counter = 0;
                clearInterval(this.checkInterval);
            }
        });

        window.addEventListener('focus', (event) => {
            this.counter = 0;
            clearInterval(this.checkInterval);
        });

        window.addEventListener('blur', (event) => {
            this.counter = 0;
            clearInterval(this.checkInterval);
            this.logout();
        });
        // if (this.activeTimer) {
        //     clearTimeout(this.activeTimer);
        // }

        // this.activeTimer = setTimeout(() => {
        //     sessionStorage.removeItem("htoken");
        //     sessionStorage.clear();
        //     this.router.navigate(["/"])
        //         .then(() => {
        //             window.location.reload();
        //         });
        // }, 60000 * 30);

        req = req.clone({
            setHeaders: {
                Authorization: "Bearer " + sessionStorage.getItem("htoken")
            }
        });

        document.getElementById("showLoader").style.display = "block";
        return next.handle(req).pipe(
            catchError(err => {
                if (err.status == 403) {
                } else {
                    if (err.error.message) {
                        this.toastr.error(err.error.message);
                    } else {
                        this.toastr.error("Somthing is wrong. Please check network connection and try again.");
                    }

                }
                throw err;
            }),
            finalize(() => {
                document.getElementById("showLoader").style.display = "none";
            })
        );
    }

    logout() {
        this.counter = 0;
        this.checkInterval = setInterval(() => {
            this.counter++;
            if (this.counter == 60 * 5) {
                clearInterval(this.checkInterval);
                sessionStorage.removeItem("htoken");
                sessionStorage.clear();
                this.router.navigate(["/"])
                    .then(() => {
                        window.location.reload();
                    });
            }
        }, 1000);
    }
}