<div class="student_details_section d-flex">
  <div class="left_bg w-25">
    <app-paper-summary [displayDetailIcon]="false"></app-paper-summary>
  </div>
  <div class="p-4 w-75">

    <h4 *ngIf="discipline && session">{{discipline.name}} - {{paperData?.paper?.code}} ({{session.name}}) </h4>

    <div class="d-flex justify-content-between mt-3 mb-1" *ngIf="submitEndTerm && !endTermMarksSubmitedOn">
      <h5 class="m-0">
        <p class="blink">Last Date Of EndTerm Marks Submission On
          <span class="selected-text">{{endTerms?.expiryOn | date:'longDate'}}</span><span class="txt-color"
            *ngIf="endTermRemainingDays">: {{endTermRemainingDays}} Days Left</span>
          <span *ngIf="isEndTermExpair" class="badge badge-danger text-white font-weight-700 text-center">
            Expaired</span>
        </p>


      </h5>
    </div>
    <div class="d-flex justify-content-between mt-3 mb-1" *ngIf="submitMidTerm && !midTermMarksSubmitedOn">
      <h5 class="m-0">
        <p class="blink">Last Date Of MidTerm Marks Submission On
          <span class="selected-text">{{midTerms?.expiryOn | date:'longDate'}}</span><span class="txt-color"
            *ngIf="midTermRemainingDays">: {{midTermRemainingDays}} Days Left</span>
          <span *ngIf="isMidTermExpair" class="badge badge-danger text-white font-weight-700 text-center">
            Expaired</span>
        </p>

      </h5>

    </div>
    <div class="d-flex justify-content-between mb-3">
      <h5 class="m-0">{{env.evaluatorType[paperData?.type]}}
        Alocated <span *ngIf="submitEndTerm"> EndTerm Marks
          {{paperData?.paper?.endTermAllocatedMarks}}</span>
        <span *ngIf="submitMidTerm"> MidTerm Marks
          {{paperData?.paper?.midTermAllocatedMarks}}</span>
      </h5>
      <!-- <h4 class="m-0">Students</h4> -->
      <div class="" *ngIf="submitMidTerm && submitEndTerm">
        <select [(ngModel)]="mode" class="text-80" name="mode" (change)="changeType()">
          <option value="">--Select Type--</option>
          <option value="mid">Mid</option>
          <option value="end">End</option>
        </select>
      </div>
    </div>
    <div class="text-80" *ngIf="paperData && midTermMarksSubmitedOn">
      <div class="text-100">
        <h5 class="m-0" *ngIf="midTermMarksSubmitedOn">
          <p class="">You have already submited the MidTerm marks on
            <span class="selected-text">{{midTermMarksSubmitedOn| date:'longDate'}} </span>
          </p>
        </h5>
      </div>
    </div>
    <div class="text-80" *ngIf="paperData && endTermMarksSubmitedOn">
      <div class="text-100">
        <h5 class="m-0">
          <p class="">You have already submited the EndTerm marks on
            <span class="selected-text">{{endTermMarksSubmitedOn| date:'longDate'}} </span>
          </p>
        </h5>
      </div>
    </div>
    <!-- <div class="d-flex"><h4>{{ 'Students' | translate }}</h4> <em (click)="studentList(paperId)" class="fa fa-plus"></em></div> -->
    <ng-template #nsf>{{ 'No Student Found' | translate }}</ng-template>
    <div class="text-80"
      *ngIf="(alreadyMarksSubmit && !midTermMarksSubmitedOn) || (alreadyMarksSubmit && !endTermMarksSubmitedOn)">
      <div class="row_color p-1 pr-2 pl-2 text-100">
        <div class="row">

          <div class="col-sm-3">
            <p class="m-0 pt-1 pb-1 font-weight-500">{{ 'Reg.no' | translate }}</p>
          </div>
          <div class="col-sm-2">
            <p class="m-0 pt-1 pb-1 font-weight-500">{{ 'Batch Start Year' | translate }}</p>
          </div>
          <!-- <div class="col-sm-2">
                        <p class="m-0 pt-1 pb-1 font-weight-500">{{ 'Session' | translate }}</p>
                    </div> -->
          <div class="col-sm-2">
            <p class="m-0 pt-1 pb-1 font-weight-500">{{ 'Full Marks' | translate }}</p>
          </div>
          <div class="col-sm-2">
            <p class="m-0 pt-1 pb-1 font-weight-500">{{ 'Marks Obtained' | translate }}</p>
          </div>
          <div class="col-sm-3">

          </div>
        </div>
      </div>
      <div class="p-1 pr-2 pl-2">
        <div *ngIf="students; else nsf" class="marks-entry-rows">
          <div *ngFor="let student of students; index as i;" class="row">
            <div class="col-sm-3">
              <p class="m-0 pt-1 pb-1 font-weight-400">{{student.registrationNumber}}</p>
            </div>
            <div class="col-sm-2">
              <p class="m-0 pt-1 pb-1 font-weight-400">{{student.batchStartYear}}</p>
            </div>
            <div class="col-sm-2">
              <p class="m-0 pt-1 pb-1 font-weight-400">{{fullMarks}}</p>
            </div>


            <!-- <div class="col-sm-2">
                            <p class="m-0 pt-1 pb-1 font-weight-400">{{student.sessionName}}</p>
                        </div>
                        <div class="col-sm-2">
                            <p class="m-0 pt-1 pb-1 font-weight-400">{{paper.fullMarks}}</p>
                        </div> -->
            <div style="padding: 3px;" class="col-sm-2"
              *ngIf="(submitMidTerm && isPreview) || (submitEndTerm && isPreview)">
              <input type="text" [(ngModel)]="student.marks" class="w-100" [ngClass]="{ 'invalid': invalidMarks[i]}"
                (keyup)="enterMarks($event,i)">
            </div>
            <div style="padding: 3px;" class="col-sm-2"
              *ngIf="(submitMidTerm && !isPreview) || (submitEndTerm && !isPreview)">
              <p class="m-0 pt-1 pb-1 font-weight-400" [ngClass]="{ 'invalid-value': invalidMarks[i]}">
                {{student.marks}}</p>
            </div>
            <div style="padding: 5px;" class="col-sm-3 text-sm-start text-center" *ngIf="isPreview"
              [ngClass]="{ 'invalid': invalidAbsents[student.id]}">
              <div class="form-check form-check-inline">
                <input class="form-check-input" (change)="onItemChange(student)" [disabled]="student.marks > 0"
                  type="radio" [(ngModel)]="student.absent" name="inlineRadioOptions{{i}}" id="inlineRadio1{{i}}"
                  value="0">
                <label class="form-check-label" for="inlineRadio1{{i}}">Absent</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" (change)="onItemChange(student)"
                  [disabled]="student.marks > 0" name="inlineRadioOptions{{i}}" id="inlineRadio2{{i}}"
                  [(ngModel)]="student.absent" value="1">
                <label class="form-check-label" for="inlineRadio2{{i}}">Zero Obtained</label>
              </div>
            </div>
            <div style="padding: 5px;" class="col-sm-3 text-sm-start text-center" *ngIf="!isPreview">
              <div class="form-check form-check-inline">
                <input class="form-check-input" disabled type="radio" [(ngModel)]="student.absent"
                  name="inlineRadioOptions{{i}}" id="inlineRadio1{{i}}" value="0">
                <label class="form-check-label" for="inlineRadio1{{i}}">Absent</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" disabled name="inlineRadioOptions{{i}}"
                  id="inlineRadio2{{i}}" [(ngModel)]="student.absent" value="1">
                <label class="form-check-label" for="inlineRadio2{{i}}">Zero Obtained</label>
              </div>
            </div>
          </div>

        </div>
        <div *ngIf="!isSubmit">
          <button *ngIf="students && students.length>0 && !isApply && !isPreview" (click)="submitMarks()"
            class="btn btn-primary float-right font-weight-500 text-90 p-1 mt-2 pr-2 pl-2">Submit <em aria-hidden="true"
              class="fa fa-arrow-right"></em></button>
          <button *ngIf="students && students.length>0 && !isPreview" (click)="changeIsPreview(isPreview)"
            class="btn btn-primary float-right font-weight-500 text-90 p-1 mt-2 pr-2 pl-2 mr-3"><em aria-hidden="true"
              class="fa fa-arrow-left"></em>Back To Edit </button>
          <button *ngIf="students && students.length>0 && isPreview" (click)="showPreview(isPreview)"
            class="btn btn-primary float-right font-weight-500 text-90 p-1 mt-2 pr-2 pl-2">Preview <em
              aria-hidden="true" class="fa fa-arrow-right"></em></button>
        </div>
      </div>
    </div>

  </div>
</div>
<div class="d-none">
  <table id="studentMarks">
    <tr>
      <th>Session</th>
      <th>Paper Code</th>
      <th>Examination</th>
      <th>Examiner</th>
      <th>Mode</th>
      <th>Term</th>
      <th>Reg. No.</th>
      <th>Batch</th>
      <th>Marks Obtained</th>
    </tr>
    <tr *ngFor="let student of studentMarks">
      <td>{{student.session}}</td>
      <td>{{student.code}}</td>
      <td>{{student.examinationName}}</td>
      <td>{{student.examinerName}}</td>
      <td>{{student.mode}}</td>
      <td>{{student.term}}</td>
      <td>{{student.registrationNumber}}</td>
      <td>{{student.batchStartYear}}</td>
      <td>{{student.marks}}</td>
    </tr>
  </table>
</div>

<div class="modal fade" id="OkModal" tabindex="-1" role="dialog" aria-labelledby="DeleteTaskModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="DeleteTaskModalLabel">Success Meaasge</h5>
        <button type="button" class="close" aria-label="Close" (click)="clickOk()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <!-- <h2>Are you sure</h2> -->
          <p>Thank you for submitting the marks {{paperData?.paper?.code}}</p>
          <button type="button" class="btn btn-sm btn-success del-btn mr-4" (click)="clickOk()">Ok</button>
        </div>
      </div>
    </div>
  </div>
</div>
