import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExportAsService } from 'ngx-export-as';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AcademiaService } from '../api-client/academia.service';
import { paperService } from '../api-client/paper.service';
import { config } from '../config';
import { UiService } from '../ui.service';

@Component({
  selector: 'app-evaluator-papers',
  templateUrl: './evaluator-papers.component.html',
  styleUrls: ['./evaluator-papers.component.css']
})


export class EvaluatorPapersComponent implements OnInit {

  private _paperServiceSubscription: Subscription;
  private _academiaServiceSubscription: Subscription;

  paperId: any;
  papers: any;
  session: any;
  disciplines: any;
  filterKeyword = "";
  filterDiscipline = [];
  filterSemester = 0;
  filterLevel = 0;
  filterSession = "";
  filters = {};
  pageSize: number = config.paginationPerPage;
  totalItemsCount: number = 0;
  totalPageCount: number = 0;
  papersCurrentPage: any = 1;
  academiaSessions: any;
  env = config;
  paperCsvHeader = config.paperCsvHeader;
  exportPapers: any;
  fromSession = "";
  toSession = "";
  disciplineSearchSettings = {
    singleSelection: false,
    text: "Select Discipline",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    badgeShowLimit: 3
  };
  copyPaper = false;
  paperCsvData = [];

  constructor(
    private _paperService: paperService,
    private _academiaService: AcademiaService,
    private _route: ActivatedRoute,
    private _uiService: UiService,
    private _tosterService: ToastrService,
    private _exportAsService: ExportAsService,
    private _router: Router) {
    this._academiaServiceSubscription = _academiaService.onDisciplinesUpdated.subscribe(_ => {
      this.academiaSessions = this._academiaService.getAcademicSession();
      this.disciplines = this._academiaService.getDisciplines();
    });
  }


  ngOnInit() {
    this._uiService.changeAppTitle.next("Papers");
    this.academiaSessions = this._academiaService.getAcademicSession();
    this.disciplines = this._academiaService.getDisciplines();
    // this.paperFilter();
    this.getPapers();
  }


  paperFilter() {
    var levels = [];
    if (this.filterLevel) {
      levels.push(Number(this.filterLevel));
    }
    this.filters = {
      "freeText": this.filterKeyword,
      "academicSessionId": this.filterSession,
      "disciplineIds": this.filterDiscipline.map(x => x.id),
      "semester": this.filterSemester,
      "levels": levels,
      "startIndex": (this.papersCurrentPage - 1) * this.pageSize,
      "pageSize": this.pageSize
    };
    this._academiaService.papers(this.filters).subscribe((result: any) => {
      this.exportPapers = result.items;
      for (var i = 0; i < result.items.length; i++) {
        result.items[i].sessionName = this.sessionDetails(result.items[i].academicSessionId).name;
      }
      this.papers = result.items;
      //console.log(result.items);
      this.totalItemsCount = result.totalCount;
      this.totalPageCount = Math.ceil(this.totalItemsCount / this.pageSize);
    })
  }
  getPapers() {
    this._academiaService.getAssignedPapers().subscribe((result: any) => {

      for (var i = 0; i < result.length; i++) {
        result[i].paper.sessionName = this.sessionDetails(result[i].paper.academicSessionId).name;
      }
      // this.papers = result;
      this.papers =result.filter((x: any) => (x.type == 2 && x.allowSubmitMidTermMarks) || (x.type == 2 && x.allowSubmitEndTermMarks));
    },
      error => {
        this._tosterService.error(error.message);
      });

  }
  sessionDetails(sessionId: any) {
    if (this.academiaSessions == null) return null;
    var items = this.academiaSessions.filter((x: any) => x.id == sessionId);
    if (items.length > 0) {
      return items[0];
    } else {
      return null;
    }
  }

  redirectTo(paper: any) {
    this._router.navigateByUrl(`student-marks-submit/${paper.id}`);
    return;
  }

}

