<!--The content below is only a placeholder and can be replaced.-->

<ng-container *ngIf="showHeader">
    <app-header-menu [title]="title"></app-header-menu>
</ng-container>
<div class="d-flex">
    <router-outlet></router-outlet>
    <router-outlet name="detailSection"></router-outlet>
</div>

<div class="quick-summary z-depth-1" [ngClass]="{'show-quick-summary':rightMainPopupOutlet.isActivated}">
    <div class="my-custom-scrollbar my-custom-scrollbar-primary">
        <router-outlet name="rightMainPopup" #rightMainPopupOutlet="outlet"></router-outlet>
        <em class="close fa fa-times" (click)="closeQuickSummary()"></em>
    </div>
</div>
<div class="center-popup" [ngClass]="{'show-center-popup':centerPopUpOutlet.isActivated}">
    <div class="center-popup-overlay"></div>
    <div class="center-popup-container z-depth-1">
        <router-outlet name="centerPopUp" #centerPopUpOutlet="outlet"></router-outlet>
        <em class="close fa fa-times" (click)="closeCenterPopup()"></em> 
    </div>
</div>
<div class="status-bar">
    <div
    class="indeterminate-progress d-none align-items-center ml-2 mr-2"    
    [ngClass]="{'d-flex':indeterminateProgressText!=null}" >
        <em class="material-icons mr-1 spin">cached</em>
        <span class="indeterminateProgressText"></span>
    </div>
</div>