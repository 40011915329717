import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuardService } from './_guards/auth-guard.service';
import { LoginGuardService } from './_guards/login-guard.service';
import { CapabilityGuardService } from './_guards/capability-guard.service';
import { HomeComponent } from './home/home.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { HodWiseDisciplineComponent } from './hod-wise-discipline/hod-wise-discipline.component';
import { PaperComponent } from './paper/paper.component';
import { ExaminersComponent } from './examiners/examiners.component';
import { PaperSummaryComponent } from './paper-summary/paper-summary.component';
import { SearchStudentsComponent } from './search-students/search-students.component';
import { DataPreviewComponent } from './data-preview/data-preview.component';
import { DataImportMapperComponent } from './data-import-mapper/data-import-mapper.component';
import { EvaluatorPapersComponent } from './evaluator-papers/evaluator-papers.component';
import { StudentMarksSubmitComponent } from './student-marks-submit/student-marks-submit.component';
import { ExaminerListComponent } from './examiner-list/examiner-list.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent,canActivate: [LoginGuardService] },
  { path: 'reset-password', component: ResetPasswordComponent,canActivate: [LoginGuardService] },
  { path: 'home', component: HomeComponent,canActivate: [AuthGuardService] },
  { path: 'examiner-allocation', component: HodWiseDisciplineComponent,canActivate: [AuthGuardService] },
  { path: 'evaluter-papers', component: EvaluatorPapersComponent,canActivate: [AuthGuardService] },
  { path: 'paper/:id', component: PaperComponent, canActivate: [AuthGuardService] },
  { path: 'student-marks-submit/:id', component: StudentMarksSubmitComponent, canActivate: [AuthGuardService] },
  { path: 'examiners/:id', component: ExaminersComponent, canActivate: [AuthGuardService] },
  { path: 'search-students/:id', component: SearchStudentsComponent,outlet: 'rightMainPopup', canActivate: [AuthGuardService] },
  { path: '', component: HomeComponent, canActivate: [AuthGuardService] },
  { path: 'paper-summary/:id', component: PaperSummaryComponent, outlet: 'rightMainPopup', canActivate: [AuthGuardService] },
  { path: 'data-preview', component: DataPreviewComponent,canActivate: [AuthGuardService] },
  { path: 'data-import-mapper', component: DataImportMapperComponent, outlet: 'centerPopUp', canActivate: [AuthGuardService] },
  { path: 'examiner-list', component: ExaminerListComponent, outlet: 'rightMainPopup', canActivate: [AuthGuardService] },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
