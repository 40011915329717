import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { config } from '../../app/config';
import { PaperModel } from '../models/paper.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class paperService {
    constructor(_http) {
        this._http = _http;
        this.onPapersUpdated = new Subject();
        _http.get(config.apiEndPointBase + "organization/departments").subscribe((result) => {
            result.sort((x, y) => {
                return x.name.localeCompare(y.name);
            });
            this._papers = result;
            this.onPapersUpdated.next();
        });
    }
    getPapers() {
        return this._papers;
    }
    /* public getPaper(id:any)
    {
      if(this._papers == null) return null;
      var items = this._papers.filter((x:any) => x.id == id);
      if(items.length>0){
        return items[0];
      }else{
        return null;
      }
    } */
    getPaper(paperId) {
        return this._http.get(config.apiEndPointBase + "Paper/" + paperId);
    }
    getEvaluators(paperId, examId) {
        return this._http.get(config.apiEndPointBase + "paper/" + paperId + "/evaluators/" + examId);
    }
    savePaper(data) {
        if (data.id) {
            return this._http
                .put(config.apiEndPointBase + "paper", data, {})
                .pipe(map((response) => {
                return new PaperModel().deserialize(response);
            }));
        }
        else {
            return this._http
                .post(config.apiEndPointBase + "paper", data, {})
                .pipe(map((response) => {
                return new PaperModel().deserialize(response);
            }));
        }
    }
}
paperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function paperService_Factory() { return new paperService(i0.ɵɵinject(i1.HttpClient)); }, token: paperService, providedIn: "root" });
