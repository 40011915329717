import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, of } from 'rxjs';
import { config } from '../config';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  private _templates: any;

  public onTemplatesUpdated = new Subject();
  public getEmpList = new BehaviorSubject({data : false});
  public getReviewerList = new BehaviorSubject({data : false});
  public getThirdExaminerList = new BehaviorSubject({data : false});
  constructor(private _http: HttpClient) {
    _http.get(config.apiEndPointBase + "utility/notification-templates").subscribe((result: any) => {
      this._templates = result;
    });

  }

  submittedEmpArray(listValue : any){
    this.getEmpList.next(listValue);
  }
  submittedReviewerArray(listValue : any){
    this.getReviewerList.next(listValue);
  }
  submittedThirdExaminerArray(listValue : any){
    this.getThirdExaminerList.next(listValue);
  }

  public getTemplate(id: any) {
    if (this._templates == null) return null;
    var items = this._templates.filter((x: any) => x.id == id);
    if (items.length > 0) {
      return items[0];
    } else {
      return null;
    }
  }

  getTemplates() {
    return this._http.get(config.apiEndPointBase + "utility/notification-templates");
  }

  save(data: any): Observable<any> {
    if (data.id) {
      return this._http
        .put(config.apiEndPointBase + "utility", data, {})
        .pipe(map((response: any) => {
          return response;
        }));
    } /* else {
      return this._http
        .post(config.apiEndPointBase + "utility", data, {})
        .pipe(map((response: any) => {
          return response;
        }));
    } */
  }

  sendNotification(department: any) {
    return this._http
      .post(config.apiEndPointBase + "action/send-examiner-allocation-request/" + department.departmentId, { departmentId: department.departmentId }, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  stopNotification(department: any) {
    return this._http
      .put(config.apiEndPointBase + "action/cancel-examiner-allocation-request/" + department.departmentId, { departmentId: department.departmentId }, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  activeExaminerDepartments() {
    return this._http
      .post(config.apiEndPointBase + "action/active-examiner-allocation-requests", {}, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  myActiveRequerst() {
    return this._http
      .post(config.apiEndPointBase + "action/my-active-requests", {}, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  setEvaluators(data: any) {
    return this._http
      .post(config.apiEndPointBase + "action/set-evaluators", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }
}
