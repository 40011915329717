import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExportAsService } from 'ngx-export-as';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AcademiaService } from '../api-client/academia.service';
import { ExamService } from '../api-client/exam.service';
import { paperService } from '../api-client/paper.service';
import { config } from '../config';
import { UiService } from '../ui.service';
declare var $: any;
@Component({
  selector: 'app-student-marks-submit',
  templateUrl: './student-marks-submit.component.html',
  styleUrls: ['./student-marks-submit.component.css']
})
export class StudentMarksSubmitComponent implements OnInit {

  private _paperServiceSubscription: Subscription;
  private _academiaServiceSubscription: Subscription;
  students: any;
  studentMarks: any;
  academiaSessions: any;
  paperInDisciplineSession: any;
  paperId: any;
  paper: any;
  session: any;
  discipline: any;
  paperSession: any;
  activeExaminationId: any;
  mode: any = "";
  paperData: any;
  env = config;
  submitMidTerm: boolean = false;
  submitEndTerm: boolean = false;
  fullMarks: any;
  isApply: boolean = false;
  invalidMarks: boolean[] = [];
  isPreview: boolean = true;
  invalidAbsents: boolean[] = [];
  midTerms: any;
  endTerms: any;
  endTermMarksSubmitedOn: any;
  midTermMarksSubmitedOn: any;
  marksSubmitOn: any;
  isSubmit: boolean = false;
  today: Date;
  isMidTermExpair: boolean = false;
  isEndTermExpair: boolean = false;
  alreadyMarksSubmit: boolean = false;
  midTermRemainingDays: string | number;
  endTermRemainingDays: string | number;
  constructor(
    private _paperService: paperService,
    private _academiaService: AcademiaService,
    private _uiService: UiService,
    private _route: ActivatedRoute,
    private _tosterService: ToastrService,
    private _examService: ExamService,
    private _exportAsService: ExportAsService,
    private _router: Router,) {
    this._academiaServiceSubscription = _academiaService.onAcademicSessionUpdate.subscribe(_ => {
      this.academiaSessions = this._academiaService.getAcademicSession();
      this.populateStudents();
    });
  }

  ngOnInit() {
    this.today = new Date();
    this._route.params.subscribe(params => {
      this.paperId = params.id;
      if (this.paperId) {
        this.paperDetails();
        this.populateStudents();
        this.activeExam();
        this.getPaperDetails(this.paperId);
      }
    });
  }

  paperDetails() {
    if (this.paperId == null) return;
    this._paperService.getPaper(this.paperId)
      .subscribe((result: any) => {
        this.paper = result;
        this._uiService.changeAppTitle.next("Paper: " + result.name);
        this.session = this._academiaService.getSessionDetails(result.academicSessionId);
        this.paperSession = { "paperId": this.paperId, "session": this.session };
        this.discipline = this._academiaService.getDiscipline(result.disciplineId);
        this._uiService.setpaperInDisciplineSession({ discipline: this.discipline, session: this.session });
        this._uiService.setpaper(this.paper);
      });
  }

  async getPaperDetails(paperId: any) {
    if (this.paperId == null) return;
    this._academiaService.getAssignedPaperDetails(paperId).subscribe((result: any) => {
      this.paperData = result;
      this.endTermMarksSubmitedOn = this.paperData.endTermMarksSubmitedOn;
      this.midTermMarksSubmitedOn = this.paperData.midTermMarksSubmitedOn;
      var midItems = this.paperData.activeRequests.filter((x: any) => x.type == 4);
      var endItems = this.paperData.activeRequests.filter((x: any) => x.type == 8);
      console.log('this.paperData',this.paperData);
      if (this.paperData.allowSubmitMidTermMarks) {
        this.submitMidTerm = true;
      } else {
        this.submitMidTerm = false;
      }
      if (this.paperData.allowSubmitEndTermMarks) {
        this.submitEndTerm = true;
      } else {
        this.submitEndTerm = false;
      }
      if (midItems.length > 0 && this.paperData.allowSubmitMidTermMarks) {
        this.mode = 'mid';
        this.fullMarks=this.paperData.paper.midTermAllocatedMarks;
        this.midTerms = midItems[0];
        this.midTermRemainingDays = this.remainingDays(this.midTerms.expiryOn);
        if (this.midTermMarksSubmitedOn) {
          this.alreadyMarksSubmit = false;
        } else {
          this.alreadyMarksSubmit = true;
        }
        if (this.today > new Date(this.midTerms.expiryOn)) {
          this.isMidTermExpair = true;
        }
      }
      if (endItems.length > 0) {
        this.mode = 'end';
        this.fullMarks=this.paperData.paper.endTermAllocatedMarks;
        this.endTerms = endItems[0];
        this.endTermRemainingDays = this.remainingDays(this.endTerms.expiryOn);
        if (this.endTermMarksSubmitedOn) {
          this.alreadyMarksSubmit = false;
        } else {
          this.alreadyMarksSubmit = true;
        }
        if (this.today > new Date(this.endTerms.expiryOn)) {
          this.isEndTermExpair = true;
        }
      }
      if (this.submitMidTerm && this.submitEndTerm) {
        this.mode = '';
        this.fullMarks=null;
      }
    },
      error => {
        this._tosterService.error(error.message);
      });

  }

  populateStudents() {
    if (this.paperId == null) return;
    this.paperInDisciplineSession = this._uiService.paperInDisciplineSession;
    this._academiaService.getPaperWiseStudents(this.paperId)
      .subscribe((result: any) => {
        for (var i = 0; i < result.length; i++) {
          var session = this.sessionDetails(result[i].academicSessionId);
          var name = '';
          if (session) {
            name = session.name;
          }
          result[i].sessionName = name;
        }
        result.sort((x: any, y: any) => {
          return x.registrationNumber.localeCompare(y.registrationNumber);
        });
        for (var k = 0; k < result.length; k++) {
          result[k].marks = 0;
          result[k].absent = 2;
        }
        this.students = result;
      });
  }

  sessionDetails(sessionId: any) {
    if (this.academiaSessions == null) return null;
    var items = this.academiaSessions.filter((x: any) => x.id == sessionId);
    if (items.length > 0) {
      return items[0];
    } else {
      return null;
    }
  }

  activeExam() {
    this._academiaService.getActiveExam().toPromise().then((result: any) => {
      this.activeExaminationId = result.id;
    });
  }

  async submitMarks() {
    if (!this.mode) {
      this._tosterService.error("Select Type");
      return;
    }
    try {
      for (var i = 0; i < this.students.length; i++) {
        var isAbsent = false;
        if (this.students[i].absent == 0) {
          isAbsent = true;
        } else {
          if (this.students[i].marks == 0 && this.students[i].absent == 2) {
            this._tosterService.error("Please select Absent or Zero Obtained");
            return;
          } else {
            isAbsent = false;
          }
        }
        if (!this.students[i].marks || this.students[i].marks == '') {
          this.students[i].marks = 0;
        }
        if (this.mode == 'mid') {
          var studentParams = {
            studentId: this.students[i].id,
            paperId: this.paper.id,
            examinationId: this.activeExaminationId,
            isMidTermMarks: true,
            marks: parseFloat(this.students[i].marks),
            studentAbsent: isAbsent,
            incomplete: false,
            mode: this.paperData.type
          }
        } else if (this.mode == 'end') {
          var studentParams = {
            studentId: this.students[i].id,
            paperId: this.paper.id,
            examinationId: this.activeExaminationId,
            isMidTermMarks: false,
            marks: parseFloat(this.students[i].marks),
            studentAbsent: isAbsent,
            incomplete: false,
            mode: this.paperData.type
          }
        }
        await this._examService.submitMarks(studentParams).toPromise();
      }

      await this._examService.sendSubmitMarksEmail(this.activeExaminationId, this.paper.id, 2, this.mode).toPromise();
      this._tosterService.success("Marks updated");
      this.isApply = true;
      this.isSubmit = true;
      this.paperData.activeRequests.splice(0, 1);
      await this.getPaperDetails(this.paperId);
      $('#OkModal').modal('toggle');
      // if (this.paperData.activeRequests.length == 0) {
      //   $('#OkModal').modal('toggle');
      // } else {
      //   await this.getPaperDetails(this.paperId);
      // }

    } catch (e) {
      this._tosterService.error(e);
    }
  }

  clickOk() {
    $('#OkModal').modal('toggle');
    this._router.navigate(["/evaluter-papers"]);
  }

  changeType() {
    if (this.mode == 'end') {
      this.fullMarks = this.paperData.paper.endTermAllocatedMarks;
      if (this.endTermMarksSubmitedOn) {
        this.alreadyMarksSubmit = false;
      } else {
        this.isApply = false;
        this.isPreview = true;
        this.isSubmit = false;
        this.alreadyMarksSubmit = true;
      }
    }
    else if (this.mode == 'mid') {
      this.fullMarks = this.paperData.paper.midTermAllocatedMarks;
      if (this.midTermMarksSubmitedOn) {
        this.alreadyMarksSubmit = false;
      } else {
        this.isApply = false;
        this.isPreview = true;
        this.isSubmit = false;
        this.alreadyMarksSubmit = true;
      }
    }
    for (var k = 0; k < this.students.length; k++) {
      this.students[k].marks = 0;
      this.students[k].absent = 2;
    }
    this.invalidMarks = [];
    this.invalidAbsents = [];
  }

  enterMarks(e: any, index: number) {
    if (this.fullMarks != 'Select Type') {
      const num = Number(e.target.value) % 1 === 0;
      if (isNaN(e.target.value)) {
        this.isApply = true;
        if (!this.invalidMarks[index] || this.invalidMarks[index] == false) {
          this.invalidMarks[index] = !this.invalidMarks[index];
        }
        this._tosterService.error("Enter valid marks");
      }
      else if (num === false) {
        const myArray = e.target.value.split(".");
        if (myArray.length > 1 && myArray[1] != 5) {
          this.isApply = true;
          if (!this.invalidMarks[index] || this.invalidMarks[index] == false) {
            this.invalidMarks[index] = !this.invalidMarks[index];
          }
          this._tosterService.error("Enter valid . number");
        }
      }
      else if (e.target.value > this.fullMarks) {
        this.students[index].absent = 2;
        this.isApply = true;
        if (!this.invalidMarks[index] || this.invalidMarks[index] == false) {
          this.invalidMarks[index] = !this.invalidMarks[index];
        }
        this.invalidAbsents[this.students[index].id] = false;
        this._tosterService.error("The entered marks " + e.target.value + " is greater than " + this.fullMarks);
      } else {
        this.invalidAbsents[this.students[index].id] = false;
        this.students[index].absent = 2;
        this.invalidMarks[index] = false;
        this.isApply = false;
        const isInvalid = this.invalidMarks.includes(true);
        if (isInvalid) {
          this.isApply = true;
        }

      }

    }
  }

  changeIsPreview(preview: boolean) {
    this.isPreview = !preview;
  }
  showPreview(preview: boolean) {
    if (!this.mode) {
      this._tosterService.error("Select Type");
      return;
    }
    var tempAbsent = [];
    var tempInvalidMarks = [];
    for (var i = 0; i < this.students.length; i++) {
      if (!this.students[i].marks || this.students[i].marks == '') {
        this.students[i].marks = 0;
      }
      if (this.students[i].marks == 0 && this.students[i].absent == 2) {
        if (!this.invalidAbsents[this.students[i].id] || this.invalidAbsents[this.students[i].id] == false) {
          this.invalidAbsents[this.students[i].id] = !this.invalidAbsents[this.students[i].id];
        }
        tempAbsent.push(i);
      }

      if (this.students[i].marks > this.fullMarks) {
        if (!this.invalidMarks[i] || this.invalidMarks[i] == false) {
          this.invalidMarks[i] = !this.invalidMarks[i];
        }
        const isInvalid = this.invalidMarks.includes(true);
        if (isInvalid) {
          this.isApply = true;
        }
        tempAbsent.push(i);
        this._tosterService.error("The entered marks " + this.students[i].marks + " is greater than " + this.fullMarks);
      }

    }
    if (tempAbsent.length == 0) {
      this.isPreview = !preview;
      this.invalidAbsents = [];
    } else {
      this._tosterService.error("Please select Absent or Zero Obtained or Valid marks");
    }

  }
  onItemChange(student: any) {
    this.invalidAbsents[student.id] = false;
  }

  remainingDays(date: any) {
    let todaydate = new Date().getTime();
    let expireDate = new Date(date).getTime();
    if (expireDate < todaydate) return "";
    let diffTime = Math.abs(new Date().valueOf() - new Date(date).valueOf());
    let days = diffTime / (24 * 60 * 60 * 1000);
    return Math.round(days);
  }

}
