import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormControl, FormsModule } from '@angular/forms';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { CustomLanguageLoader } from './utility/custom-language-loader';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
//import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { ToastrModule } from 'ngx-toastr';
import { HomeComponent } from './home/home.component';
import { HeaderMenuComponent } from './header-menu/header-menu.component';
import { ApiClientModule } from './api-client/api-client.module';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PaperSummaryComponent } from './paper-summary/paper-summary.component';
import { PaperComponent } from './paper/paper.component';
import { ExaminersComponent } from './examiners/examiners.component';
import { PaperWiseStudentsComponent } from './paper-wise-students/paper-wise-students.component';
import { HodWiseDisciplineComponent } from './hod-wise-discipline/hod-wise-discipline.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { WebServiceInterceptor } from './api-client/web-service-interceptor';
import { ExportAsModule } from 'ngx-export-as';
import { SearchStudentsComponent } from './search-students/search-students.component';
import { DataImportMapperComponent } from './data-import-mapper/data-import-mapper.component';
import { DataPreviewComponent } from './data-preview/data-preview.component';
import { EvaluatorPapersComponent } from './evaluator-papers/evaluator-papers.component';
import { StudentMarksSubmitComponent } from './student-marks-submit/student-marks-submit.component';
import { ExaminerListComponent } from './examiner-list/examiner-list.component';

import { environment } from '../environments/environment';
declare const ENV_OVERWRITE: any;

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    PaperSummaryComponent,
    PaperComponent,
    ExaminersComponent,
    PaperWiseStudentsComponent,
    HeaderMenuComponent,
    ResetPasswordComponent,
    HodWiseDisciplineComponent,
    SearchStudentsComponent,
    SearchStudentsComponent,
    DataImportMapperComponent,
    DataPreviewComponent,
    EvaluatorPapersComponent,
    StudentMarksSubmitComponent,
    ExaminerListComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NoopAnimationsModule,
    MDBBootstrapModule.forRoot(),
    AngularMultiSelectModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    //AngularFontAwesomeModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useClass: CustomLanguageLoader }
    }),
    ToastrModule.forRoot(),
    ApiClientModule,
    ExportAsModule
  ],
  providers: [
    {
      provide: OWL_DATE_TIME_FORMATS, useValue: {
        parseInput: 'LL LT',
        fullPickerInput: 'YYYY-MM-DD', /* <---- Here i've rewrited the format */
        datePickerInput: 'LL',
        timePickerInput: 'LT',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MM YYYY',
      }
    },
    { provide: HTTP_INTERCEPTORS, useClass: WebServiceInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(){
    if(ENV_OVERWRITE){
      for(const key in ENV_OVERWRITE){
        environment[key] = ENV_OVERWRITE[key];
      }
    }
  }
}
