import { Component, OnInit, Input, OnDestroy, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { paperService } from '../api-client/paper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { config } from '../../app/config';
import { PaperModel } from '../models/paper.model';
import { ToastrService } from 'ngx-toastr';
import { paperFormBuilder } from './paper.form-builder';
import { OrganizationService } from '../api-client/organization.service';
import { AcademiaService } from '../api-client/academia.service';

@Component({
  selector: 'app-paper-summary',
  templateUrl: './paper-summary.component.html',
  styleUrls: ['./paper-summary.component.css']
})
export class PaperSummaryComponent implements OnInit, OnChanges, OnDestroy {

  @Input() paperId: any;
  @Input() displayDetailIcon: boolean = true;

  private _paperServiceSubscription: Subscription;
  private _academiaServiceSubscription: Subscription;
  private _academiaDisciplineServiceSubscription: Subscription;
  paper = {};
  editMode: boolean = false;
  env: any;
  disciplines: any;
  submitted = false;
  model = new PaperModel();
  paperForm: any;
  sessions: any;

  constructor(
    private _paperService: paperService,
    private _route: ActivatedRoute,
    private _toster: ToastrService,
    private _academiaService: AcademiaService,
    private _organizationSearvice: OrganizationService,
    private _formBuilder: paperFormBuilder,
    private _router: Router
  ) {
    this._academiaServiceSubscription = _academiaService.onAcademicSessionUpdate.subscribe(_ => {
      this.sessions = this._academiaService.getAcademicSession();
    });
    this._academiaDisciplineServiceSubscription = _academiaService.onDisciplinesUpdated.subscribe(_ => {
      this.disciplines = this._academiaService.getDisciplines();
    });

    this._paperServiceSubscription = _paperService.onPapersUpdated.subscribe(_ => {
      this.paperDetails();
    });

  }

  get f() { return this.paperForm.controls; }

  ngOnInit() {
    this.paperForm = this._formBuilder.getForm();
    this.sessions = this._academiaService.getAcademicSession();
    this.disciplines = this._academiaService.getDisciplines();
    this._route.params.subscribe(params => {
      this.paperId = params.id;
      this.resetForm();
      if (this.paperId)
        this.paperDetails();
    });

    this.env = config;
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    this.paperDetails();
  }

  paperDetails() {
    if (this.paperId == null) return;
    this._paperService.getPaper(this.paperId)
      .subscribe((result: any) => {
        this.paper = result;
        this._formBuilder.setValues(result);
      });
  }

  redirectToPaperDetails(paper: any) {
    this._router.navigateByUrl("/paper/" + paper.id);
  }

  ngOnDestroy(): void {
    this._paperServiceSubscription.unsubscribe();
    this._academiaDisciplineServiceSubscription.unsubscribe();
    this._academiaServiceSubscription.unsubscribe();
  }

  editPaper() {
    this.editMode = false;
  }

  onSubmit() {
    this.submitted = true;
    if (this.paperForm.invalid) {
      return;
    }

    this.model.deserialize(this.paperForm.value);
    this._paperService.savePaper(this.model)
      .subscribe(
        (response: PaperModel) => {
          this._toster.success("Paper saved");
          return false;
        },
        error => {
          this._toster.error(error.message);
        }
      )
  }

  resetForm() {
    this.submitted = false;
    this.paperForm.reset();
  }

}
