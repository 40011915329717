import { Component, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { paperService } from '../api-client/paper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AcademiaService } from '../api-client/academia.service';
import { UiService } from '../ui.service';

@Component({
  selector: 'app-paper',
  templateUrl: './paper.component.html',
  styleUrls: ['./paper.component.css']
})
export class PaperComponent implements OnInit, OnDestroy {

  private _paperServiceSubscription: Subscription;
  paperId: any;
  paper: any;
  session: any;
  discipline: any;
  paperSession: any;

  constructor(
    private _paperService: paperService,
    private _academiaService: AcademiaService,
    private _uiService: UiService,
    private _route: ActivatedRoute,
    private _router: Router, ) {
      this._route.params.subscribe(params => {
        this.paperId = params.id;
        if (this.paperId)
          this.paperDetails();
      })
  }

  ngOnInit() {    
    this._route.params.subscribe(params => {
      this.paperId = params.id;
      if (this.paperId)
        this.paperDetails();
    })
  }

  paperDetails() {
    if (this.paperId == null) return;
    this._paperService.getPaper(this.paperId)
      .subscribe((result: any) => {
        this.paper = result;
        this._uiService.changeAppTitle.next("Paper: "+result.name);
        this.session = this._academiaService.getSessionDetails(result.academicSessionId);
        this.paperSession = {"paperId":this.paperId,"session":this.session};
        this.discipline = this._academiaService.getDiscipline(result.disciplineId);
        this._uiService.setpaperInDisciplineSession({ discipline: this.discipline, session: this.session });
        this._uiService.setpaper(this.paper);
      });
  }

  ngOnDestroy(): void {
    //this._paperServiceSubscription.unsubscribe();
  }

}
