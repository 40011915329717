import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { AcademiaService } from '../api-client/academia.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.css']
})
export class HeaderMenuComponent implements OnInit, OnDestroy {
  public navClass: String = '';

  @Input() title: string;

  sessions: any;
  selectedSession: any;
  langName: any = "English";
  private _academiaServiceSubscription: Subscription;

  constructor(
    private _router: Router,
    private _academiaService: AcademiaService) {
    this._academiaServiceSubscription = _academiaService.onAcademicSessionUpdate.subscribe(_ => {
      this.sessions = this._academiaService.getAcademicSession();
      this.selectedSession = this._academiaService.getActiveAcademicSession();
    });
  }

  ngOnInit() {
    this.sessions = this._academiaService.getAcademicSession();
    this.selectedSession = this._academiaService.getActiveAcademicSession();
  }

  toggleNavMenu() {
    this._router.navigateByUrl('/menu');
    //this.navClass = (this.navClass == 'nav-open')?'':'nav-open';
  }

  redirectTo(arg: any) {
    this._router.navigateByUrl(arg);
    //this.toggleNavMenu();
  }

  selectSession(ele: any) {
    this._academiaService.setSelectedSession(ele);
  }

  ngOnDestroy(): void {
    this._academiaServiceSubscription.unsubscribe();
  }

  changeLanguage(langCode: any, langName: any) {
    this.langName = langName;
  }

  logout(){
    sessionStorage.removeItem("htoken");
    this._router.navigate(["/login"]);
  }

}
