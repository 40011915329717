<div class="home_section">    
    <div class="flex-grow-1 m-4 configure_list d-flex text-80 font-weight-500">
        <div class="w-50 mr-2">
            <div class="configure_button mb-3">                
                <a [ngClass]="{'btn-primary':allowPaperConfiguration,'disabled':!allowPaperConfiguration}" routerLink="/examiner-allocation" class="btn text-100 font-weight-500 z-depth-0 mr-2 w-100">Paper Configure</a>                
            </div>
            <!-- <div class="item-row-list p-1">
                <p class="m-0">Lorem ipsum dummy text typetesting industry</p>
            </div>             -->
        </div>
        <div class="w-50">
            <div class="configure_button mb-3">                
                <a [ngClass]="{'btn-primary':allowMarksSubmission,'disabled':!allowMarksSubmission}" routerLink="/evaluter-papers" class="btn btn-secondary text-100 font-weight-500 z-depth-0 w-100">Marks Capture</a>                
            </div>
            <!-- <div class="item-row-list p-1">
                <p class="m-0">Lorem ipsum dummy text typetesting industry</p>
            </div>            -->
        </div>
    </div>
</div>